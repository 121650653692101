
.header{
    background-color: #ffffff;
   
}

/*

@media (min-width: 576px){
.container, .container-sm {
    max-width: 540px;
}
}

@media (min-width: 768px){
.container, .container-md, .container-sm {
max-width: 720px;
}
}



@media (min-width: 992px){
.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}
}



@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
max-width: 1140px;
}
}

@media (min-width: 1400px){
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
}
}



@media (min-width: 1401px){
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1319px;
}
}
*/



.wc-column{
    height: 100%;
    float: left;
}

.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.px-0 {
    padding-right: 0!important;
    padding-left: 0!important;
}


.navbar-expand-md .navbar-nav {
    flex-direction: row;
}

@media (min-width: 768px){
.navbar-expand-md .navbar-nav {
    flex-direction: row;
}
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
}

.menulinks{
    display: flex;
}

.button-primary{
    background-color: #471BF5;
    padding:10px;
    color:#ffffff;
    border-radius: 16px;
}

.button-primary-lg{
    background-color: #471BF5;
    padding:10px;
    color:#ffffff;
    width: 100%;
}
header{
    position: fixed;
    width: 100%;
    z-index: 1;
}
.main{
    height: 100%;
    background-color: #EDEDED;
    display: flex;
    padding-top: 100px;
}

.innerBgColor{
    background-color: #F4F8FB;
}

.borderedbox{
    background-color: #ffffff;
    border-radius: 6px;
    padding: 10px;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin:15px;
}

.borderedboxnopad{
    background-color: #ffffff;
    border-radius: 6px;
    margin:15px;
}

.unborderedboxnopad{
    border-radius: 6px;
    margin:15px;
}

.wc-row{
    width: 100%;
    padding:10px;
}

.wc-column-20{
    width: 20%;
    display: inline-block;
    margin-right: 7.5px;
    vertical-align: top;
}

.wc-column-80{
    width: 80%;
    display: inline-block;
    margin-left: 7.5px;
    vertical-align: top;
}
.box_title{
    color: #1A0A5B;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Montserrat';
}
.box_title_lg{
    color: #1A0A5B;
    font-size: 21px;
    font-weight: 700;
    font-family: 'Montserrat';
}
.divider{
    background-color: #D9D9D9;
    height: 1px;
    margin: 20px;
}
.box_list{
    margin-left:15px;
    margin-top: 20px;
}
.box_list li{
    color: #212121;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 19px;
}

.adsection{
    background-color: #F3F5F9;
    min-height: 400px;
    border-radius: 10px;
    margin-top: 100px;
}

.notificationheader{
    padding-left:40px;
    padding-top: 40px;
    padding-bottom: 20px;
}

.box_desc_sm{
    font-family: 'Montserrat';
    font-size: 15px;
    margin-top: 10px;
}

.settings-row{
    display: flex;
    padding-left: 40px;
    padding-top: 20px;
}

.floatright{
    text-align: center;
    float: right;
}

.form-row{
    padding-left: 40px;
    padding-top: 20px;
    width: 80%;
}

.form-row input{
    width: 100%;
    margin-top: 13px;
}

.settings-column1{
    width: 50%;
    padding-right: 75px;
}

.settings-column2{
    width: 50%;
}
.wc_heading_sm{
    font-family: 'Montserrat';
    color: #212121;
    font-size: 13px;
    font-weight: 600;
}

.wc_text_sm{
    font-family: 'Montserrat';
    color: #212121;
    font-size: 13px;
    font-weight: 400;
    padding-top: 7px;
}

.wc_text_sm_bold{
    font-family: 'Montserrat';
    color: #212121;
    font-size: 13px;
    font-weight: 600;
    padding-top: 7px;
}
.switchrow{
    display: -webkit-box;
    margin-bottom: 30px;
}

.item_single{
    padding-left: 10px;
}
.switchrow label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }
  
  .switchrow label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 5px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
 }

.switchrow input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
}
  
.switchrow input:checked + label {
    background: #471BF5;
}

.switchrow input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.switchrow label:active:after {
    width: 130px;
}

.nav-item{
    font-family: 'Montserrat';
    color: #212121;
    font-size: 13px;
    font-weight: 400;
    padding-top: 7px; 
    padding-right: 50px;
}
.nav-link{
    line-height: 21px;
    font-weight: 600;
}
.nav-link span{
    float: left;
    padding-right: 6px;
}
.nav-link span img{
    width: 20px;
}

.settings-menu-item{
    font-family: 'Montserrat';
    color: #212121;
    font-size: 13px;
    font-weight: 400;
    padding-top: 7px; 
    padding-right: 50px;
    padding-bottom: 20px;
}

.settings-menu-item span{
    float: left;
    padding-right: 18px;
}

.settings-menu-item span img{
    width: 20px;
}

.btn-main{
    font-family: 'Montserrat';
    background-color: #471BF5;
    padding: 10px;
    border-radius: 7px;
    padding-left: 20px;
    padding-right: 20px;
    color:#fff;
    font-weight: 600;
    font-size: 13px;
}

.c20 {
	width: 20%;
}
.c25{
    width: 25%;
}

.c30 {
	width: 30%;
}

.c40 {
	width: 40%;
}

.c50 {
	width: 50%;
}


.c60{
    width:60%;
}


.c70{
    width:70%;
}


.c90{
    width:90%;
}

.c80 {
	width: 80%;
}

.rowheight-full{
     height: 100vh;
}

.rowheight-200{
    height: 120px;
}

#profile_top{
    height: 120px;
}

.btn{
    padding:15px;
    text-align: center;
}
.btn-wc-primary{
    background-color:#572FF4;
    color:#ffffff;
    font-family: 'Montserrat';
}
.fl{
    float: left;
    display: block;
    width: 100%;
}
.option-section{
    display: flex;
}

.texted{
    color:#46474c;
    padding:15px;
    text-align: center;
}

.profilesearch{
    width: 100%;
    height: 88px;
}

.font20{
    font-family: 'Montserrat';
    font-size: 20px;
}

.semiboldtexted{
    font-weight: 600;
    color:#000;
    text-align: center;
}
.boldtexted{
    font-weight: 800;
    color:#000;
    text-align: center;
}

.savedfilters{
    padding:15px;
}

.component-26-child{
    height: 89px;
}

.h3title{
    font-family: 'Montserrat';
    font-size: 20px;
    color:#393999;
    font-weight: 700;
    padding:15px;
    text-align: left;

}

.job-card-parent {
    margin: 10px;
}

.job-card {
    position: relative;
    border-radius: 15.81px;
    background-color: var(--color-white);
    overflow: hidden;
    flex-shrink: 0;
    padding:20px;
    width: 100%;
    margin-bottom: 20px;
}

.imgbox{
    float: left;
}

.titlebox{
    float: left;
    padding-left:10px;
}

.labelbox{
    float: right;
}

.vuesaxbulklocation-icon6{
    float: left;
    vertical-align: middle;
}

.place-parent{
    padding-top: 10px;
}

.placename{
    font-family: 'Montserrat';
    font-size: 20px;
    color:#000;
    line-height: 30px;
}
.army-public-school6{
    font-family: 'Montserrat';
    font-size: 25px;
    color:#000;
    line-height: 40px;
}

.firstrow, .secondrow{
    display: inline-block;
    position: relative;
    width: 100%;
}

.item-rating{
    margin-top:10px;
}

.jobtitle{
    color:#46474c;
    font-family: 'Montserrat';
    font-size:16px;
    font-weight: 600;
    padding-top: 15px;
}

.jobdesc{
    color:#46474c;
    font-family: 'Montserrat';
    font-size:16px;
    padding-top: 15px;
}
.share-button{
    border:1px solid;
    padding:15px;
    border-radius: 10px;
    text-align: center;
    float: left;
}
.thirdrow{
    padding-top: 25px;
}


.applybutton button{
    width: 100%;
}
 

.share-job {
    line-height: 27px;
    font-size: 18px;
    padding-left: 20px;
    display: flex;
}

.applybtnlg{
    width: 100%;
    font-size: 18px;
    line-height: 33px;
}
.optionheader{
    display: flex;
}

.optioneachrow{
    width: 100%;
    display: flex;
  
}

.optionflr{
   
    display: inline-block;
    width: 100%;
    text-align: right;
}

input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #393999;
    width: 25px;
    height: 25px;
    border: 0.15em solid #393999;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
}
.vuesaxlinearsearch-normal-icon2{
    float: left;
}
.searchbox{
    width: 100%;
    border-radius: 10px;
    
}
.left_searchbox{
    width: 85%;
    border: none;
    background-color: #d9d9d9;
    line-height: 15px;
}

.bestmatches{
    font-size: 18px;
}
.texted{
    font-size: 18px;
}

.nav-link {
    line-height: 21px;
    font-weight: 600;
    font-size: 20px;
}

.share-button{
    cursor: pointer;
}

.job-card-parent {
    margin: 10px;
    display: block;
}

.bookmarked{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.optioneachrow{
    color:#46474c;
    font-weight: 600;
    font-size:18px;
    font-family: 'Montserrat';
}


.roundersmall{
    width: 40px;
}
.suggestedjobtext{
    line-height: 40px;
    padding-left: 10px;
    font-size: 15px;
    float: left;
}

.boxtitle{
    font-family: 'Montserrat';
    font-size: 20px;
    color: #393999;
    font-weight: 700;
    padding: 15px;
    text-align: left;
    line-height: 16px;
    padding-left: 0px;
}

.boxtitle img{
    float: left;
    width: 16px;
    margin-right: 5px;
}

.ads{
    padding-bottom: 40px;
}

.formlabel{
    font-family: 'Montserrat';
    font-size: 14px;
    color:#6b7280;
}

.formgroup label{
    font-family: 'Montserrat';
    font-size: 14px;
    color:#6b7280;
    margin-bottom:10px;
}

.forminputcls{
    width: 100%;
    border-radius: 7px;
    margin-top: 5px;
    margin-bottom: 15px;
}
.subtitle_popup{
    font-size: 16px;
    font-weight: bold;
}

.orsec span{
    font-family: 'Montserrat';
    font-size: 20px;
    color: #393999;
    font-weight: 700;
}

.orsec{
    padding: 13px;
    text-align: center;
}

.shareprofiler{
    font-family: 'Montserrat';
    font-size: 17px;
    color: #393999;
    font-weight: 700;
}

.afterapplying{
    font-family: 'Montserrat';
    font-size: 17px;
    color: #393999;
    font-weight: 700;
    text-align: center;
    padding: 40px;
}

.wc-column {
    height: 100%;
    float: left;
    display: grid;
}

.menubutton{
    display: flex;
}

.avatar img{
    width: 50px;
}

/*
.menubutton .nav-item{
    padding-top: 20px;
    padding-right: 20px;
} */


.avatar{
 cursor: pointer;   
}

.formtags{
    display: flex;
    margin-bottom: 40px;
}
.tageachline{
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    width: auto;
    float: left;
    color: #46474c;
    margin-right: 14px;
}

.formlabel {
    font-family: 'Montserrat';
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 7px;
}

.xclosebtn{
    color: #000;
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
    padding-left: 15px;
}

.profile-dropdown .menuitemeach{
    font-size: 16px;
    font-family: 'Montserrat';
    padding: 10px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
}

.wd20per {
    width: 20%;
    display: inline-grid;
}

.wd60per {
    width: 60%;
    display: inline-grid;
}

.footer {
    /* padding-bottom: 7px; */
    background-color: #FFF0FF;
    /* padding: 100px; */
    display: block;
    padding-left: 100px;
    padding-top: 80px;
    padding-right: 100px;
    padding-bottom: 50px;
}

.footerhead{
    font-family: 'Montserrat';
    font-size: 18px;
    color: #393999;
    font-weight: 700;
    padding: 15px;
    text-align: left;
}

.footerlinks{
    font-family: 'Montserrat';
    font-size: 18px;
    color: #393999;
    padding: 15px;
    text-align: left;
    padding-top: 0px;
}

.footerlinks li{
    font-family: 'Montserrat';
    color: #393999;
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.copyright{
    text-align: center;
    padding: 15px;
    font-family: 'Montserrat';
    color: #393999;
    font-size: 15px;
}
.share-menu{
    width: 100%;
    display: flex;
    gap: 14px;
}

.component-22{
    cursor: pointer;
}

.component-23{
    cursor: pointer;
}

.formgroup{
    display: block;
    clear: both;
}

.formsection{
    margin: 20px;
    padding: 20px;
}
.profilephoto img{
    width: 125px;
    float: left;
    border-radius: 50px;
    margin-right: 25px;
}

.profilephoto{
    margin-bottom: 100px;
}
.text-right{
    text-align: right;
}

.phonenfield{
    width: 93%;
    border-radius: 0 4px 4px 0px;
}

.profilephoto span{
    margin-top: 12px;
    display: block;
    font-size: 13px;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;}
  /*.dropdown:hover .dropdown-content {display: block;}*/
  /*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/

  .act_icon{
    cursor: pointer;
    font-size: 30px;
    line-height: 15px;
  }

  .expandbtn{
    color:#1a0a5b;
    font-family: 'Montserrat';
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    text-align: right;
    line-height: 30px;
    padding-right: 10px;
  }

  .expandbtn img{

    float: right;
    /* vertical-align: middle; */
    width: 25px;
    display: flex;
    margin-left: 10px;

  }
 .savefilter{
    display: flex;
    padding: 10px;
    margin: 10px;
 }

 .savefilter span{
    text-align: left;
    width: 50%;
    cursor: pointer;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
 }

 .savefilter .clear{
    font-family: 'Montserrat';
    font-size: 16px;
    text-align: right;
    padding: 10px;
    margin: 10px;
    font-weight: 300;
    margin-right: 30px;

 }
 
 .btn-main-full{
    display: block;
 }

 .downloads{
    cursor: pointer;
 }

 .innermainblock{
    height: 100%;
 }

 .tablike{
    cursor: pointer;
    font-family: 'Montserrat';
    background-color: #ffffff;
    padding: 15px;
    border-radius: 7px;
    padding-left: 20px;
    padding-right: 20px;
    color:#000;
    font-weight: 600;
    font-size: 16px;
 }

 .activetab{
    cursor: pointer;
    font-family: 'Montserrat';
    background-color: #471BF5;
    padding: 15px;
    border-radius: 7px;
    padding-left: 20px;
    padding-right: 20px;
    color:#fff;
    font-weight: 600;
    font-size: 16px;
 }

  .box_list li{
    border-radius: 2px;
    padding: 15px;
    margin-bottom: 10px;
  }

  .box_list li.active{
    background: #F3F5F9;
    
  }

  /*
  .filterbox{
    height: 250px;
    overflow-y: scroll;
  }
  */

  .optiondropdown{
    position: relative;
    display: inline-block;
  }


  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 185px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .jobdetail{
    padding:30px;
 
  }
.courselist{
  background-color: #F4F4F4;
  padding: 40px;
  border-radius: 10px;
}
.courserow{
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;
}
.courserow.active{
    border: 1px solid lightgray;
    border-radius: 10px;
}
.courseicon{
    width: 150px;
}

.courseicon img{

    border-radius: 10px;

}
.course_detail{
    padding: 20px;
    margin: 20px;
}
.courseinfo{
    width: 100%;
    padding-left: 20px;
}

.courseinfo_title{
    font-family: 'Montserrat';
    font-size: 20px;
    color: #000;
    font-weight: 600;
    line-height: 60px;
}
.courseinfo_desc{
    font-family: 'Montserrat';
    font-size: 20px;
    color: #000;
    line-height: 25px;
}
.courseinfo_ins{
    font-family: 'Montserrat';
    font-size: 17px;
    color: #000;
    line-height: 10px;
}

.coursedetailtitle{
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 600;
    color: #393999;
    line-height: 25px;
}

.courseduration{
    font-family: 'Montserrat';
    font-size: 18px;
   
    line-height: 25px; 
    margin-top: 20px;
    margin-bottom: 20px;
}
.courseduration img{
    width: 25px;
    display: inline;
    margin-right: 10px;
}

.coursecertificates{
    font-family: 'Montserrat';
    font-size: 18px;
 
    line-height: 25px; 
    margin-top: 20px;
    margin-bottom: 20px;
}
.coursecertificates img{
    width: 30px;
    display: inline;
    margin-right: 10px;
}

.tag{
    font-family: 'Montserrat';
    padding: 10px;
    border: 1px solid;
    border-radius: 115px;
    display: inline-block;
    min-width: 80px;
    text-align: center;
    background-color: #ffffff;
    color: #000;
    margin-right:10px;
}

.tag.active{
    font-family: 'Montserrat';
    padding: 10px;
    border: 1px solid;
    border-radius: 115px;
    display: inline-block;
    min-width: 80px;
    text-align: center;
    background-color: #471BF5;
    color: #fff;
}

.coursetypes{
    display: inline-block;
    margin-left: 16px;
}

.coursetypes li{
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 40px;
}

.adsapce{
    margin: 15px;
    height: 200px;
    /* width: 200px; */
    background-color: beige;
    text-align: center;
    vertical-align: middle;
}


.textright{
    text-align: right;
}

.wc_logo img{
    display: inline-block;
}
.wc_footer_subscribe{
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 15px;
    color: #471BF5;
    padding-top: 15px;
    padding-bottom: 15px;
}
.inputsubscribe{
    font-family: 'Montserrat';
    background-color: #2B2E3C;
    color: #FFFFFF;
    line-height: 28px;
    border-radius: 5px 0px 0px 5px;
}
.btn-subscribe{
    font-family: 'Montserrat';
    font-size: 12px;
    background-color: #471BF5;
    height: auto;
    border-radius: 0 5px 5px 0px;
    color: #FFFFFF;
    font-weight: 600;
}

.wd10per{
    width: 10%;
    display: inline-grid;
}
.wd30per{
    width: 30%;
    display: inline-grid;
}

#salaryText{
    font-family: 'Montserrat';
    padding: 10px;
    color: #471BF5;
    font-size: 18px;
    font-weight: 600;
}

.btn-plain{
    font-family: 'Montserrat';
    padding: 10px;
    color:#000;
    border-radius: 10px;
    border: 1px solid;
    margin-right: 30px;
}
.btn-plain.active{
    font-family: 'Montserrat';
    color: #ffffff;
    background-color: #471BF5;
}
.btn-upload-sm{
    font-family: 'Montserrat';
    background-color: #471BF5;
    padding: 10px;
    border-radius: 7px;
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    margin-top: 10px;
}



.bloginfo{
    position: absolute;
    top: 300px;
    left: 30px;
}

.bloginfo h2{
    font-size: 50px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #fff;
    line-height: 60px;
    text-transform: capitalize;
}

.blogshort{
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #fff;
    line-height: 60px;
}

.blogbtn{
    display: flex;
}
.category_tags{
    margin: 20px;
    padding: 20px;
}

.tag{
    cursor: pointer;
}

.bloglist{
    display: flex;
}

.innermainblock {
    height: 100%;
}
 

.blogitem{
    width: 300px;
}

/*
.blogimg img{
    border-radius: 10px;
    width: 300px;
}
*/

.blogimg img {
    border-radius: 10px;
    width: 300px;
    height: 160px;
}

.blog_middle{
    padding-left: 100px;
    padding-right: 100px;
}

.title_small{
    padding: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
}

.desc_small{
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
}
.blogdetail{
    margin-bottom: 20px;
}
.bloglink{
    display: flex;
    margin-left: 20px;
}
.plainlink{
    padding: 14px;
    border: 1px solid #471BF5;
    border-radius: 10px;
    font-size: 14px;
    color:#471BF5;
}
.plainlink_small{
    padding: 10px;
    border: 1px solid #471BF5;
    border-radius: 10px;
    font-size: 14px;
    color:#471BF5;
}
.trending_title{
    padding: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 10px;
}
.trending_desc_small{
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
}

.trending_extra a{
    padding-left:20px;
    font-size: 14px;
    color:#471BF5;
}
.trendingitemrow{
    margin-bottom: 20px;
}

.trendingnow h3{
    padding: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px; 
    color:#471BF5;
}

.trending_extra{
    margin-top: 10px;
}

.heart {
    fill: red;
    position: relative;
    top: 0px;
    width: 20px;
    animation: pulse 1s ease infinite;
    display: inline-block;
}
.curated_head{
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #471BF5;
    padding-bottom: 20px;
}
.curated_section{
    display: flex;
    gap: 50px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 40px;
}
.curated_list{
    width: 70%;
}
.featuredblog{
    display: block;
    width: 100%;
    border-radius: 20px;
    margin: 20px;
    background-size: cover;
    height: 500px;
}
.featured_blog_info{
    width: 100%;
    margin-top: 300px;
    padding-left: 40px;
}
.featurdblog_title{
    font-size: 25px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #FFFFFF;
    padding-bottom: 20px;
}
.featuredblog_desc_small{
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.blog_main_content img{
   
    width:640px;
}
.blog_main_header{
    background-color: lightcyan;
    padding: 20px;
    height: 250px;
}
.blog_main_title{
    text-align: center;
    font-size: 35px;
    margin-top: 35px;
    font-family: 'Montserrat';
    font-weight: 600;
}
.blog_main_content{
    font-size: 18px;
    font-family: 'Montserrat';
    margin: 10px !important;
    padding:10px !important
}


.blog_main_footer{
    padding:50px;
    margin:70px;
}
.comment_head{
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #471BF5;
    padding-bottom: 20px;
}

.rdw-editor-main {
    height: 500px !important;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid lightgray;
    margin-bottom: 40px;
    padding: 10px;
}

.editor-title-row{
    display: flex;
}

.editorsection{
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 50px;
    padding-right: 50px;
}

.height100px{
    height: 100px;
}
.editortoprow{
    display: flex;
    border-bottom: 1px solid #46474c;
    margin-bottom: 20px;
}
.editorrow-start{
    width: 100%;
    text-align: start;
    font-family: 'Montserrat';
    font-size: 16px;
    color: #393999;
    font-weight: 700;
    padding: 15px;
    line-height: 40px;
    
}

.editormiddlerow{
    padding-left: 100px;
    padding-right: 100px;
}

.editorsection .inputbox{
    padding-left: 25px;
    width: 95%;
}
.editorrow-end{
    width: 100%;
    text-align: end;
    font-family: 'Montserrat';
    font-size: 16px;
    color: #393999;
    font-weight: 700;
    padding: 15px;
}

.editor-title-row .label{
    font-family: 'Montserrat';
    color: rgb(90, 87, 87);
    font-size: 13px;
    line-height: 40px;
    width: 8%;
}
 
.editorrow-start img {
    float: left;
    padding-right: 10px;
}

.label img{
    float: left;
    padding-right: 10px;
}

.topbar_course{
    height: 60px;
}

.wc-column{
    height: auto !important;
    display: block;
}
/*
.blog_main_header{
    background-color: #333333;
    height: 245px;
}*/

.blog_main_header {
    background-color: #333333;
    height: 280px;
    text-align: center;
}

.blog_main_header img{
    display: inline-block;
    width: 980px;
    text-align: center;
}

.inner_header_blog{
    width: 980px;
    margin-left:auto;
    margin-right: auto;
    text-align: left;
}   

.blog_main_title{
    color:#000;
    font-family: 'Montserrat';
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
}
.blog_by{
    padding-top: 30px;
    color: #ffffff;
    font-family: 'Montserrat';
    font-size: 13px;
}

.blog_main_content{
    width: 1020px;
    margin-left:auto;
    margin-right: auto;
}

.blog_main_footer{
    width: 980px;
    margin-left:auto;
    margin-right: auto;
}

.blog_main_content p {
    margin: 20px !important;
    line-height: 30px;
}

.blog_main_content {
    font-size: 16px;
    font-family: 'Montserrat';
}

.blog_main_footer {
    width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.comment_head {
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: #471BF5;
    padding-bottom: 20px;
}

.comment_row{
    padding-bottom: 20px;
     
}

.c_toprow{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    align-items: center;
}

.c_name{
    width: 100%;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.c_avatar{
    border-radius: 50px;
    width: 100px;
}
.c_reply{
    text-align: end;
    width: 100%;
    font-size: 12px;
    font-family: 'Montserrat';
    cursor: pointer;
}
.c_bottomrow{
    font-size: 13px;
    font-family: 'Montserrat';
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
}
.c_name span {
    font-size: 13px;
    font-weight: normal;
    padding-left: 40px;
}
.c_avatar img{
    border-radius: 50px;
}
.textareacss {
    background-color: #F4F4F4 !important;
    border-radius: 10px;
}
.sendbtn{
    margin-top: 10px;
}
.sendbtn span{
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: normal;
}
.style-module_emptyIcons__Bg-FZ {
    display: flex !important;
}
.empty-icons {
    display: flex !important;
}
.filled-icons {
   display: -webkit-inline-box !important;
}
.ratings{
    padding-top: 10px;
    padding-bottom: 50px;
}

.r_toprow{
    display: flex;
    gap: 20px;
}

.textareainputcssblue{
    border: 1px solid #471BF5;
    border-radius: 10px;
}

.r_avatar img{
    border-radius: 50px;
}

.replybox{
    margin-top: 20px;
}

.blog_slider_section{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.greentext{
    color:green;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.redtext{
    color:red;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.blog_s_head{
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #471BF5;
    padding-bottom: 6px;
    width: 100%;
}

.viewall{
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #471BF5;
    padding-bottom: 6px;
    width: 100%;
    text-align: end;
    cursor: pointer;
}

/* mobile classes */
.mobiletopsection {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 16px;
}
.menuicon{
    width: 100%;
}
.menuicon img{
    width: 30px;
    text-align: right;
    float: right;
}

.footer_menu {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: row;
    padding: 10px;
    padding-top: 20px;
}

.mobileicons{
    width: 100%;
    text-align: center;
}
.mobileicons img{
    width: 25px;
    margin-left:auto;
    margin-right: auto;
} 

.mobileicons span{
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 600;
    text-align: center;
    line-height: 30px;
}

.mainvh {
    height: 100%;
    height: 100%;
    background-color: #EFEFEF;
    /* display: flex; */
    padding-top: 100px;
}

.search_icon_m{
    position: relative;
}

.search_icon_m img{
    position: absolute;
    bottom: 25px;
    width: 80px;
    border: 1px solid lightgrey;
    border-radius: 50px;
    background-color: #471BF5;
}

.job-card-parent-mobile{
    padding-bottom: 20px;
}

.labelbox {
    float: right;
    font-size: 11px;
}


.menu-overlay {
    background-color: #191919;
    color: #ffffff;
    height: 100%;
    width: 100%;
    position: fixed;
    text-align: center;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .menu-overlay.open {
    opacity: 1;
    visibility: visible;
  }

  .closemenu{
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
  }

  .one .nav-links{
    font-size: 16px;
    line-height: 25px;
    font-family: 'Montserrat';
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 700;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .overlay-menu{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .one li {
    border-bottom: 1px solid #af9494;
  }

  .one img{
    width: 30px;
    filter: brightness(0) invert(1);
  }

  .category_tags{
    padding-left: 0px;
    margin-left: 0px;
  }

  .writeauthor{
    margin-top:20px;
  }

  .viewallcomments{
    text-align: center;
  }


  .comment_list_overflow {
    height: 300px; 
    overflow-y: scroll;
  }

  .you{
    font-size: 12px;
    line-height: 24px;
    font-family: 'Montserrat';
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 10px;
  }

 footer{
    bottom: 0px;
    position: fixed;
    width: 100%;
 }

 .footer_menu{
    background-color: #ffffff;
 }

 .course_detail {
    padding: 20px;
    margin: 20px;
    margin-top: 0px;
    padding-top: 0px;
}

.c100{
    width: 100%;
}

.coursedetailtitle {
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
    color: #393999;
    line-height: 25px;
}

.avatar {
    cursor: pointer;
    margin-right: 30px;
}

.ul_othercourses li{
    padding: 10px;
    background-color: aliceblue;
    border-radius: 10px;
    margin: 10px;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
}

.ul_othercourses div{
    margin-bottom: 8px;
}

.ul_othercourses a{
    color: blue;
}

.notetoothers{
    padding: 20px;
    font-family: 'Montserrat';
    font-size: 18px;
    color: green;
}


.slick-prev:before, .slick-next:before {
    color: #393999 !important;
}

/* advisory */
.pagetitle{
    color: #471BF5;
    font-family: 'Montserrat';
    font-size: 23px;
    font-weight: 600;
    padding-bottom: 30px;
}

.advisorysection{
    width: 100%;
    margin-left:auto;
    margin-right: auto;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tiles{
    display: flex;
    gap: 40px;
    width: 100%;
    flex-direction: row;
    align-items: normal;
    margin-bottom: 40px;
}

.tilesingle{
    border-radius: 10px;
    height: 100%;
}

.buildingbg {
    background-size: cover;
    height: 400px;
    width: 300px;
    position: relative;
}

.buildingbg span{
    margin-bottom: 0px;
    position: absolute;
    bottom: 40px;
    left: 20px;
    color: #fff;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 20px;
}
/* advisory */

/* whychooseus */
.bgcms_blue{
    background-color: #F4F8FB;
}
.whychooseus{
    /*width: 100%;*/
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.wctitle_large{
    color: #393999;
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 700;
    padding-bottom: 25px;
}

.wcdesc_normal {
    width: 100%;
    font-family: 'Montserrat';
}
.whychoose_sec1{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 170px;
}

.imagetextflex{
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;
    gap: 40px;
    align-items: center;
}

.imgleft{
    width: 500px;
}

.textright_title{
    color: #393999;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
}


.aboutus_sec3 {
    margin-bottom: 90px;
    margin-left: 30px;
    margin-right: 30px;
}

.txtright{
    width: 70%;
}

.tag.others{
    float: right;
}

.ul_othercourses{
    display: inline-flex;
}

.notetoothers {
    padding: 20px;
    font-family: 'Montserrat';
    font-size: 16px;
    color: green;
}

.bookmarking{
    text-align: end;
  }
  .bookmarking img{
    width: 40px;
  }


.wcdesc_normal {
    font-family: 'Montserrat';
}

.imagetextflex {
    display: flex;
    margin-top: 160px;
    margin-bottom: 100px;
    gap: 70px;
    align-items: center;
}
/*
.imgleft {
    width: 440px;
}

.txtright {
    width: 50%;
}
*/

.imgleft {
    width: 100%;
}

.txtright {
    width: 100%;
}

.profilesearch {
    width: 100%;
    height: 88px;
    border-radius: 10px 0px 0px 10px;
    font-size: 20px;
    font-family: 'Montserrat';
}

.act_icon {
    cursor: pointer;
    font-size: 30px;
    line-height: 15px;
     
}

.tablike{
    text-align: center;
}

.dropdown-content{
    right: 35px;
    top: 85px;
}

.dropdown-content a:hover{
    color:#000;
    font-weight: bolder;
}

.avatar.clicked{
    border: 5px solid #572FF4;
    border-radius: 50px;
}

.thirdrow {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    align-items: center;
}

.share-button {
    cursor: pointer;
    width: auto;
}

.union-parent{
    display: flex;
    align-items: center;
}

.thirdrow {
    padding-top: 15px;
}
.imgbox {
    float: left;
    width: 100px;
}

.jobtype{
    line-height: 25px;
}

.item-rating{
    width: 100px;
}

.expandbtn {
    color: #1a0a5b;
    font-family: 'Montserrat';
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    text-align: right;
    line-height: 30px;
    padding-right: 10px;
    padding-bottom: 0px;
}

.army-public-school6 {
    font-family: 'Montserrat';
    font-size: 20px;
    color: #000;
    line-height: 25px;
}

.bookmarking img {
    width: 40px;
    padding-top: 5px;
}

.share-button {
    border: 1px solid;
    padding: 12px;
    border-radius: 10px;
    text-align: center;
}

.applybtnlg {
    width: 100%;
    font-size: 18px;
    line-height: 25px;
}

header {
    position: fixed;
    width: 100%;
    z-index: 4;
}

.progress-circle {
    font-size: 20px;
    margin: 20px;
    position: relative;
    padding: 0;
    width: 200px;
    height: 200px;
    background-color: #F2E9E1;
    border-radius: 50%;
    line-height: 5em;
}

.react-autosuggest__suggestions-list{
    padding: 15px;
    background-color: #fff;
    box-shadow: 5px 0px 20px lightgray;
    border-radius: 10px;
    height: 300px;
    overflow-y: scroll;
}

.react-autosuggest__suggestions-list li{
    line-height: 30px;
    font-family: 'Montserrat';
    font-size: 15px;
}

.rangeslider {
    margin: 35px 0;
    position: relative;
    background: #e6e6e6;
    touch-action: none;
}

.forminputcls {
    width: 100%;
    border-radius: 7px;
    margin-top: 5px;
    margin-bottom: 0px;
}

.formgroup {
    display: block;
    clear: both;
    margin-top: 5px;
    margin-bottom: 12px;
}

.rangeslider-horizontal {
    height: 12px;
    border-radius: 10px !important;
    margin-bottom: 35px !important;
    margin-top: 30px !important;
}

.btn-main:disabled,
.btn-main[disabled]{
    background-color: lightgray;
}

.errormsg{
    color: green;
    font-weight: 800;
}
.successmsg{
    color: green;
    font-weight: 800;
}

.cms_section{
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    background-color: #ffff;
    border-radius: 10px;
    padding: 30px;
    height: 750px;
    overflow-y: scroll;
    margin-bottom: 100px;
}

.cms_title{
    color: #1A0A5B;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Montserrat';
}

.cms_desc{
    margin-top: 20px;
    font-family: 'Montserrat';
    font-size: 15px;
}

p{
    line-height: 25px;
    margin-bottom: 10px;
}
.loadmorebtn{
    text-align: center;
    margin: 16px;
}

.btn-main-large{
    font-family: 'Montserrat';
    background-color: #471BF5;
    padding: 10px;
    border-radius: 7px;
    padding-left: 20px;
    padding-right: 20px;
    color:#fff;
    font-weight: 600;
    font-size: 13px;
}

.title_small{
    text-transform: capitalize;
}

.slideritems{
    width: 50%;
    margin: 40px;
}

.trendingnow{
    width: 100%;
}

.blogitem {
    width: 300px;
    padding-left: 60px;
}

.nodata{
    margin:20px;
}

.blogimg{
    padding-left: 20px;
}

.courseinfo_title {
    font-family: 'Montserrat';
    font-size: 20px;
    color: #000;
    font-weight: 600;
    line-height: 30px;
    padding-top: 10px;
}

.courseimg img{
    width: 100%;
}

.watermark{
    position: absolute;
    z-index: 1;
    top: 348px;
    opacity: 6;
}



/* plan page */
.pricing-table-box {
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
	width: 100%;
}

.pricing-table-box td {
    padding: 0px;
    border-left: 1px solid transparent;
}

.pricing-table-box .pricing-table-box-list td {
    padding: 10px 8px;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    background: #F9F9F9;
}

.pricing-table-box .pricing-table-box-list .glyphicon-ok {
    color: #68AF27;
}

.pricing-table-box .pricing-table-box-list .glyphicon-remove {
    color: #C22439;
}

.pricing-table-box .pricing-table-box-list td:first-child {
    font-weight: 400;
    text-align: left;
    border-left: 0px;
    font-size: 18px;
    color: #060606;
}

.pricing-table-box .pricing-table-box-list:nth-child(2n) td {
    background: #F2F2F2;
}

.pricing-table-box .pricing-table-box-text h2 {
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    color: #8a8a8a;
}

.pricing-table-box .pricing-table-box-text p {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #666;
    margin-top: 10px;
}

.pricing-table-box .pricing-table-box-item {
    color: #FFF;
}

.bewisemagic{
    background-color: #393999!important;
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-head {
    padding: 10px 5px;
    text-align: center;
    background-color: #393999;
    box-shadow: 0 -2px rgba(0, 0, 0, .1) inset;
    -moz-box-shadow: 0 -2px rgba(0, 0, 0, .1) inset;
    -webkit-box-shadow: 0 -2px rgba(0, 0, 0, .1) inset;
    -o-box-shadow: 0 -2px rgba(0, 0, 0, .1) inset;
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-head p {
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-price {
    padding: 15px 10px;
    text-align: center;
    color: #009AD7;
    background: #F9F9F9;
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-price p {
    font-size: 31px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 0px;
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-price p span {
    font-size: 12px;
    font-weight: 300;
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-price > span {
    font-size: 12px;
    font-weight: 400;
    color: #009AD7
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-purchase {
    padding: 10px 5px;
    background: #FFF;
}

 

.pricing-table-box .pricing-table-box-list:nth-child(1n) td.second-row {
    background: #F2F2F2;
}

.pricing-table-box .pricing-table-box-list:nth-child(2n) td.second-row {
    background: #dedede;
}

.pricing-table-box .pricing-table-box-list:nth-child(1n) td.fourth-row {
    background: #F2F2F2;
}

.pricing-table-box .pricing-table-box-list:nth-child(2n) td.fourth-row {
    background: #dedede;
}

.fa-check{
    color:green;
    font-size: 20px;
}

.fa-times{
    
    font-size: 20px; 
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-price p span {
    font-size: 15px;
    font-weight: 600;
}

.pricing-table-box .pricing-table-box-list:nth-child(1n) td.second-row {
     font-style: italic;
    font-weight: 600;
}

.bolder{
    font-weight: 600 !important;
}

.pricing-table-box .pricing-table-box-item .pricing-table-box-item-price p span {
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.pricing-table-box .pricing-table-box-list td {
    padding: 10px 8px;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    background: #F9F9F9;
    font-style: italic;
    font-weight: 600;
}

.plansection{
    background-color: #ffffff;
    margin:40px;
    padding:40px;
    border-radius: 10px;
}

.first-row.bolder{
    text-align: center;
    font-style: initial;
    font-size: 22px;
} 

.footer_btns{
    display: flex;
}

.applybutton button {
    /* width: 100%; */
    line-height: 16px;
}


.union-parent {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: max-content;
}

.viewjob_footer_btns{
    display: flex;
    gap:35px;
}

.union-icon{
    width: 20px;
}

.share-job {
    line-height: 16px;
    font-size: 16px;
    padding-left: 13px;
    display: flex;
}

.flexrow{
    display: flex;
    gap:40px;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.flexitem{
    width: 100%;
}

.coursetypes {
    display: inline-block;
    margin-left: 16px;
    margin-bottom: 25px;
}

.courserow.active {
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: #ffffff;
}
.thirdrow {
    display: flex;
    /* width: 100%; */
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.viewbtn{
    display: flex;
}

.jobdesc {
    color: #46474c;
    font-family: 'Montserrat';
    font-size: 15px;
    padding-top: 15px;
    line-height: 24px;
}

.pricing-table-box .pricing-table-box-text h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    color: #393999;
}

.smallnotes{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    padding-top: 30px;
    padding-left: 5px;
    color:#393999;
}

.help ul{
    list-style: none;
}

.help ul li{
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    color:#393999;
}

.spacer{
    background-color: #fff;
    height: 100px;
    display: block;
}

.launchtext{
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    padding-top: 30px;
    padding-left: 5px;
    font-style: italic;
    color:#393999;
    width: 100%;
    text-align: center;
    
}
.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 35px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 30px !important;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.trending_extra a {
    padding: 10px;
    font-size: 14px;
    color: #471BF5;
}

.trending_extra {
    margin-top: 10px;
    margin-left: 20px;
}

.trendingnow h3 {
    padding: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    color: #471BF5;
}

.editor-title-row {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
.h2title {
    font-family: 'Montserrat';
    font-size: 24px;
    color: #393999;
    font-weight: 700;
    padding: 20px 5px;
    text-align: left;
}

.selectdropdowns{
    display: inline-block;
    margin-bottom: 50px;
    width: 100%;
}

.dropdowncss{
    border-radius: 10px;
    /* margin: 15px; */
    width: 100%;
}
.courseinfo_desc {
    font-family: 'Montserrat';
    font-size: 16px;
    color: #000;
    line-height: 25px;
}

.coursedetail_item {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 15px 0px;
}

.courseapplybutton{
    display: flex;
}

.c_label {
    font-size: 16px;
    color: #393999;
    font-family: 'Montserrat';
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 5px;
}

.c_value{
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 600;
}


.coursedetailtitle {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    color: #393999;
    line-height: 35px;
    border-bottom: 1px solid lightgrey;
}


.spacer25 {
    background-color: #fff;
    height: 25px;
    display: block;
}

.courseicon {
    width: 80px;
}

.courseinfo {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.courseinfo_title {
    font-family: 'Montserrat';
    font-size: 16px;
    color: #000;
    font-weight: 600;
    line-height: 25px;
    padding-top: 10px;
}

.courselist {
    background-color: #F4F4F4;
    padding: 40px;
    border-radius: 10px;
    padding-right: 0px;
}

.courserow.active {
    border: 1px solid lightgray;
    border-radius: 0px;
    background-color: #ffffff;
    border-right:0px;
}

.full_detail{
    margin-top: 20px;
    margin-bottom: 20px;
}

.rowitem {
    width: 100%;
    display: flex;
    padding-bottom: 10px;
}

.w50{
    width: 50%;
}

.courseimg img {
    width: 600px;
    height: 300px;
}

.ul_othercourses {
    display: block;
}

.ul_othercourses li {
    padding: 10px;
    background-color: aliceblue;
    border-radius: 10px;
    margin: 10px;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    width: 50%;
}

.bgforcourse{
    background-position: center;
    background-size: cover;
    min-height: 200px;
    margin-bottom: 25px;
    background-color: #393999;
}

.course_h3title{
    font-family: 'Montserrat';
    font-size: 40px;
    color: #fff;
    font-weight: 700;
    padding: 30px;
    padding-bottom: 10px;
    text-align: left;
}

.semibordered{
    border-radius: 10px 10px 0px 0px;
}

.course_desc{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    font-family: 'Montserrat';
    font-size: 18px;
    color: #fff;
}

.coursebuttons{
    display: flex;
    gap:20px;
    align-items: center;
}

.hlink{
    font-family: 'Montserrat';
    color: #393999;
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.hlink i{
    font-size: 25px;
    color: #393999;
}

.middlecolumovfscrl {
    height: 100vh;
    overflow-y: scroll;
    display: inline-block;
}

 

/**
 * Simplecomplete
 */
.autocompleter {
    width: 90%;
    background: #F4F8FB;
    position: absolute;
    top: 60px;
    /* left: 0; */
    z-index: 100;
    padding: 10px;
    display: none;
    border-radius: 0px 0px 10px 10px;
}

.autocompleter,
.autocompleter-hint {
    position: absolute;
}

.autocompleter-list {
    
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.autocompleter-item-selected {
    background: #ffffff;
}

.autocompleter-item {
    padding: 6px 12px;
    color: #444444;
    font-family: 'Montserrat';
    font-size: 14px;
    cursor: pointer;
}

.autocompleter-item:hover {
    background: #fff;
}

.autocompleter-item strong {
    background: #f9de8f;
    text-shadow: 0 1px 0 #ffffff;
}

.autocompleter-hint {
    color: #ccc;
    text-align: left;
    top: -56px;
    font-weight: 400;
    left: 0;
    width: 100%;
    padding: 12px;
    font-size: 24px;
    display: none !important;
}

.autocompleter-hint span {
    color: transparent;
}

.autocompleter-hint-show {
    display: block;
}

.autocompleter-show {
    display: block;
}


.autocompleter-closed {
    display: none;
}

.searchbox {
    position: relative;
}

.hlink{
    cursor: pointer;
}
#featuredblog{
    border-radius: 20px;
    margin: 0 auto;
}

#featuredblog div {
    
    border-radius: 20px;
}

.borderedradius{
    width: 100%;
    height: 500px;
    border-radius:10px;
}
/*
#featuredblog div.show {
    display: inline;
}
*/

.adsimg{
    display:none;
}

.blog_bottom_section{
    margin-top: 70px;
    margin-bottom: 70px;
}

.blogpage h3 {
    padding: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    color: #471BF5;
}

.viewallpadded{
    text-align: center;
    padding: 15px;
}

.one_article{
    width: 100%;
}

.three_article{
    width: 100%;
}

.flex_section_gap_50 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}

.three_article .trendinglist{
    text-align: left;
}

.banner_article{
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    position: relative;
}

.bottomaligned{
    bottom: 0px;
    position: absolute;
    margin: 30px;
}

.trending_title_white{
    font-family: 'Montserrat';
    font-size: 30px;
    color: #ffff;
    text-align: left;
}

.trending_desc_small_white{
    font-family: 'Montserrat';
    color: #ffff;
    text-align: left;
}
.viewlinksmall{
    color: #471BF5;
    background-color: #ffff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.paddingtb{
    padding-top: 50px;
    padding-bottom: 50px;
}

.textright_title {
    color: #393999;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
     
}

.home_hero{
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 70px 70px;
    background-position-y: 290px;
}

.glitter img{
    width: 50px;
    position: absolute;
    animation: zoom-in-zoom-out 1s ease infinite;
}
.section_courses{
    position: relative;
}
.flower img{
    width: 50px;
    /*animation: zoom-in-zoom-out 1s ease infinite;*/
}
.leftposition{
    position: absolute;
    float: right;
    z-index: 3;
    bottom: 70px;
    top: auto;
}

.rightposition{
    position: absolute;
    float: right;
    z-index: 3;
    bottom: 0px;
    top: 50px;
    right: 60px;
}
.bglinecss{
    background-repeat: no-repeat;
    background-size: 200px ;    
}

.section_head_desc_courses {
    color: #393999;
    font-size: 40px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin: 60px;
}
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

.bluebgs{
    background-color: #E6E0FF !important;
}  

.slick-dots li button:before{
    font-size: 26px !important;
}
.slick-dots li.slick-active button:before{
    color:#393999 !important;
}

.spacer {
    height: 140px !important;
}

.whychooseus img{
    border-radius: 10px;
}

.wc_div{
    display: block;
    padding: 10px;
    text-align: justify;
}

.whychoose_sec1 {
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}

.textright_title {
    color: #393999;
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 600;
}

.bgplaceright{
    background-position: right;
}

.whitebgbordered{
    border-radius: 10px;
    background-color: #ffffff;
    padding:10px;
}

.watermark {
    position: absolute;
    z-index: 1;
    margin-top: -7%;
    opacity: 6;
    left: 40%;
    top:unset;
}

.buildingbg {
    background-size: cover;
    height: 500px;
    width: 100%;
    /* width: 300px; */
    position: relative;
}

.profilephoto img {
    width: 125px;
    float: left;
    border-radius: 65px;
    margin-right: 25px;
    height: 125px;
}

.whychoose_sec1 {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 50px !important;
    /*background-position: 425px 214px;*/
}

.heroleft_content_section {
    width: 50%;
    text-align: center;
}

/*
.whychoose_sec1 {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 100px;
}
*/

.wcdesc_normal {
    font-family: 'Montserrat';
    width: 60%;
}

.txtright {
    width: 100%;
     
}
.imgleft {
    width: 65%;
}

.whychooseus img {
    border-radius: 10px;
    width: 500px;
}

 

.flex_section_gap_50{
    padding-left: 40px;
    padding-right: 40px;
}

.trendingitemrowview{
        display: block;
}

.h3title {
    font-family: 'Montserrat';
    font-size: 18px;
    color: #393999;
    font-weight: 700;
    padding: 15px;
    text-align: left;
    padding-left: 0px;
}

.boxed-check-group, .boxed-check-group .boxed-check {
    position: relative;
    margin-bottom: 10px;
    
    /*display: flex;
    gap: 10px;*/
    display: grid; 
    grid: auto auto / auto auto auto;
    grid-gap: 10px;
}


.filtertop{
    display:flex;
    border-bottom: 1px solid #393999;
    margin-bottom: 20px;
}

.filtertitle{
    width: 100%;
    text-align: start; 
}
.savefilterbtn{
    width: 100%;
    text-align: end;
}
.btnlink{
    cursor: pointer;
}

.btnlink:hover{
    color:#393999;
}

.autocompleter {
    width: 60%;
    background: #F4F8FB;
    position: absolute;
    top: 47px;
    left: 10px;
    z-index: 100;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #393999;
}

.savefiltertxt{
    padding-left:5px;
    font-family: 'Montserrat';
}

.blogitem {
    /* width: 300px !important;*/
    /* padding-left: 60px; */
    padding-left:0px !important;
}

.trendinglist{
    padding-left: 20px;
    padding-top: 10px;
}

.flexitem {
    width: 100%;
    display: flex;
    align-items: start;
}

.bloginfo_img{
    width: 25%;
}

.bloginfo_img img{
    border-radius: 10px;
}

.bloginfo_text{
    width: 75%;
}
.nodatamessage{
    padding: 20px;
    background-color: #ffff;
    border-radius: 10px;
    color: red;
    text-align: center;
    font-family: 'Montserrat';
    margin: 10px;
}

.blog_main_content {
    width: 1080px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.webad{
    padding: 20px;
}

.webad img{
    margin-left: auto;
    margin-right: auto;
}

.blog_main_content img {
    /* height: 360px; */
    width: 500px;
}
.disabled{
    background-color: darkgray;
    color:#191919;
}
.toptext_para{
    margin: 10px;
    padding: 20px;
    background-color: #ffff;
    border-radius: 10px;
    font-size: 18px;
    font-family: 'Montserrat';
    
    font-weight: normal;
    text-align: left;
}
.toptext_para p{
    line-height: 25px;
    font-size: 16px;
}
.forminputcls{
    font-size: 13px;
    font-family: 'Montserrat';
}

.filterappluybtn{
    display: grid;
    margin-top: 40px;
}

.curated_list {
    width: 100%;
}

.rotate180{
    transform: rotate(180deg);
}

.btn-main{
    cursor: pointer;
}

.bloginfo_img img {
    border-radius: 10px;
    width: 190px;
    /*height: 110px;*/
}

.slick-dots {
    bottom: -50px;
}

.blogdetail {
    margin-bottom: 10px;
    height: 90px;
}

.extblog_title {
    font-family: 'Montserrat';
    font-size: 16px;
    color: #000;
    font-weight: 600;
    line-height: 25px;
    padding-top: 5px;
    text-transform: capitalize;
}

.extblog_extra {
    display:inline-grid;
     /* margin-left: 20px; */
}

.listed{
    display: inline;
}

/* new Signin */
.wrapper {
    max-width: 500px;
    width: 100%;
    height: 470px;
    margin: auto;
    top: 50%;
    left: 50%;
    background: #ffffff;
   /*box-shadow: -3px -3px 7px rgba(68, 75, 87, 0.356), 2px 2px 5px rgba(68, 75, 87, 0.356);*/
    border-radius: 20px;
    font-family: 'Montserrat';
}
  
  .signup .container{
    padding: 35px 40px;
  }
  
  .wrapper .tabs ul{
    width: 100%;
    margin-bottom:30px;
     
    display: flex;
  }
   
      
  
  .wrapper .tabs ul li{
    font-family: 'Montserrat';
    width: 50%;
    padding: 15px 0;
    text-align: center;
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: #e3e4e6;
    cursor: pointer;
    position: relative;
    transition: all 0.5s ease;
  }
  
  .wrapper .tabs ul li.active{
    background: #393999 ;
    color: #fefffe;
   
   
  }
  
  .wrapper .input_field{
    margin-bottom: 25px;
    margin-top: 28px;
  }
  
  .wrapper .input_field .input{
    width: 100%;
    border: none;
    color: black;
    font-family: 'Montserrat';
    border: 2px solid #393999;
    font-size: 14px;
    border-radius: 10px;
  } 
  
  .wrapper .btn{
    margin-top: 30px;
    background: #471BF5;
   
    padding: 12px;
    text-align: center;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: bold;
  }
  .wrapper .btn:hover{
   background: #471BF5;
  }
  
  .wrapper .btn a{
    color: rgb(255, 255, 255);
    display: block;
    font-size: 16px;
    font-family: 'Montserrat';
  }
  .icon-button{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    gap:10px;
  }
  .icon-button span{
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 20px;
    padding-bottom: 7px;
    border-radius: 5px;
    line-height: 30px;
    background: #b8bec7;
    /* margin-right: 5px; */
    text-align: center;
  }
  .icon-button span.facebook{
    background: #3498db;
    color: #fbfdff;
  }
  .icon-button span.google{
    background: #db7f34;
    color: #ffffff;
  }
  .icon-button span.facebook:hover{
    background: #2172a8;
  }
  .icon-button span.google:hover{
    background: #be6c29;
  }
  .icon-button span i{
    font-size: 25px;
  }
  .signgform_logo{
    width: 100%;
    text-align: center;
    display: inline-flex;
    padding: 40px;
  }
  .signform{
    position: relative;
    padding: 100px;
    width: 100%;
  }
  .logoinform{
    width: 200px;
    display: inline-block;
    margin: auto;
  }


  .buildingbg span {
    margin-bottom: 0px;
    position: absolute;
    bottom: 40px;
    left: 20px;
    color: #393999;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 20px;
    box-shadow: 10px 0px 25px #ffffff;
    background: #fff;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.watermark {
    position: absolute;
    z-index: 1;
    margin-top: -4%;
    opacity: 6;
    left: 40%;
    top: unset;
}

.blog_main_content ul li{
    line-height: 25px;
    padding-bottom: 10px;
}

.blog_main_content ul{
    padding: 20px;
}

.blog_main_content h1{
    padding: 20px;
    font-size: 21px;
}

.blog_main_content h1 span{
    font-weight: 600 !important;
}

.blog_main_content h2{
    padding: 20px;
    font-size: 19px;
    text-align: left;
}

.blog_main_content h2 span{
    font-weight: 600 !important;
}

.blog_main_content h3{
    padding: 20px;
    font-size: 21px;
    text-align: left;
}

/*
.section_howitworks .slick-dots {
    top: -25px;
    bottom: unset;
}
*/

.tabletsview{
    padding:20px;
    margin:20px;
}

.tabletsview .trendinglist{
    margin: 20px;
    padding: 20px;
}

.tabletsview .bloginfo_img {
    width: 35%;
}

.tabletsview .bloginfo_img img {
    width: 100%;
    height: 100%;
}

.tabletsview .trending_title{
    background-color: unset !important;
}

.tabletsview .trending_title_white {
    font-family: 'Montserrat';
    font-size: 21px;
    color: #000;
    text-align: left;
    padding-left: 50px;
}
.tabletsview .trending_desc_small_white {
    font-family: 'Montserrat';
    color: #000;
    text-align: left;
    padding-left: 50px;
    padding-top: 10px;
}

.tabletsview  .fb_img {
    padding: 50px;
}

.trending_title_black a{
    font-family: 'Montserrat';
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.trending_title_black{
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
}

.featureblog_block{
    margin: 30px;
}

.blog_img img{
    border-radius: 10px;
}

.trending_extra {
    margin-top: 10px;
    margin-left: 0px; 
    display: flex;
}

.trending_title{
    padding:0px;
}

.orspace{
    text-align: center;
    padding-top: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #393999;
}

.nav-item .active {
    color: #393999;
    background-color: #E6E0FF;
    border-radius: 25px;
    padding: 10px;
}

.nav-link {
    line-height: 21px;
    font-weight: 600;
    font-size: 18px;
}

.nav-link{
    border-radius: 25px;
    padding: 10px;
}

.signinsignupspace{
    padding-top:50px;
    padding-bottom: 50px;
}

.flexrowstart{
    display: flex;
    align-items: start;
    flex-direction: row;
    gap:10px;
}

.imagetextflex {
    display: flex;
    margin-top: 60px;
    margin-bottom: 10px;
    gap: 70px;
    align-items: center;
}

.aboutus .imagetextflex {
    display: flex;
    margin-top: 60px;
    margin-bottom: 10px;
    gap: 70px;
    align-items: center;
}

.aboutus_sec2 .textright_title {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    width: 168px;
    margin-left: auto;
    margin-right: auto;
}

.imgright{
    width: 400px;
}

.sharevls{
    list-style: disc;
    display: inline-block;
    text-align: left;
}

.oursharedvaluetitle {
    font-family: 'Montserrat';
    font-size: 30px;
    color: #393999;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
}

.here300x900{
    margin-left: auto;
    margin-right: auto;
}
.img300x900{
    width: 100%;
}
.aditem{
    width: 100%;
    display: inline-block;
}

.ads{
    margin-bottom: 35px;
}

.slick-slide img {
    display: block;
}
.whychooseus{
    width: auto !important; 
}
.whychooseus .wcdesc_normal{
    width: 100% !important;
}

.whychooseus p{
    text-align: left;
}

.teamflexrow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
}

.twitter img{
    width: 30px;
}

.linkedin img{
    width: 30px;
}

.membername img {
    border-radius: 10px;
    width: 235px;
}

.mobilelayout .tiles{
    flex-direction: row;
}

.mobilelayout .buildingbg {
    width: 100%;
    height: 300px;
}

.mobilelayout .buildingbg span {
    margin-bottom: 0px;
    position: absolute;
    bottom: 40px;
    left: 20px;
    color: #393999;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 15px;
    box-shadow: 10px 0px 25px #ffffff;
    background: #fff;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    right: 20px;
    padding: 10px;
}

#overlayadvisory {
    position: absolute;
    width: 100%;
    height: 85%;
    top: 240px;
    left: 0;
    /* right: 0; */
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}

.tilesblock{
    position: relative;
}
 #overlayadvisorybackup {
    position: fixed; /* Sit on top of the page content */
     
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  #overlayimg{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }

 .heading {
    font-size: 85px;
    text-transform: uppercase;
    padding: 20px;
    background: #fff;
    color: #393999;
    transform: skewY(-10deg);
    font-weight: 600;
    line-height: 95px;
    font-family: 'Montserrat';
}

.toptext {
    background: #F4F8FB;
    padding: 10px;
    /* margin: 13px; */
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
}

.home_hero {
    background-color: #F4F8FB;
    display: flex;
    padding: 20px;

}

.section_courses {
    background-color:#E6E0FF;
    padding: 35px;
    text-align: center;

}

.section_blogs{
    background-color:#E6E0FF;
    padding: 35px;
    text-align: center;
    /*height: 358px;*/
}

.margin8{
    margin-left: 10%;
    margin-right: 10%;
}

.flex_section_gap_50 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 10%;
    margin-right: 10%;
}

.whychooseus {
    margin-left: 10%;
    margin-right: 10%;
}

.whychoose_sec1 {
    background-size: 90%;
    background-position: 60px 40px !important;
}

.belowheading{
    font-family: 'Montserrat';
    font-size: 18px;
    color: #000000;
   
    border-radius: 20px;
    padding: 40px;
    text-align: left;
}

.collageimg img {
    width: 100%;
}

.aboutus_sec2{
    margin-bottom: 90px;
}

.bloglist-flex{
    display: flex;
    align-items: center;
    justify-content: left;
    gap:70px;
}

.post_in_category{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
}

.blogitem {
    width: 300px;
    padding-left: 60px;
}

.trendinglist_flex{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.blog_bottom_section {
    margin-top: 70px;
    margin-bottom: 70px;
    margin-left: 20px;
    margin-right: 20px;
}

.trendingnow_section{
    margin-top: 50px;
}

.btnlinkwhite{
    color:#fff;
    cursor: pointer;
}

.gridonly{
    display: unset !important;
}

.wc-column-grid {
    height: 100%;
    float: left;
    display: grid;
}

.wcflexrow{
    display: flex;

}

.profileblock{
    width: 80%;
}
 
#overlayloginrequire {
    position: fixed;
    width: 100%;
   
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    background-color: rgba(250, 247, 247, 0.88);
    z-index: 2;
    cursor: pointer;
    filter:unset;
  }
  
  #text{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: #393999;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }

.blurred{
    filter: blur(8px);
}  

.profileblock{
    width: 20%;
}

.aboutuseblock{
    width: 80%;
}

.social-login-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}

.social-login-buttons .facebook{
    width: 40px;
    height: 40px;
    padding: 10px;
    text-align: center;
}

.social-login-buttons .google{
    width: 40px;
    height: 40px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.social-login-buttons .linkedinicon{
    width: 40px;
    height: 40px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.detailarticleimg{
    float: left;
    width: 400px;
    padding: 20px;
}

.scrollery{
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
}

.bigtext{
    color: #393999;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin: 20px;
    text-align: center;
}

.smallslogan {
    color: #393999;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 58px;
    text-align: center;
}

 
.flexitem{
    gap:20px;
}

.blogheading{
    padding: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    color: #471BF5;
    text-align: left;

}

.flex_section_gap_50{
    align-items: start;
}

.trending_title{
    text-align: left;
}

.padleft{
    padding-left: 20px;
}

.wctitle_large {
    color: #393999;
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 25px;
}

.hero_head {
    font-family: 'Montserrat';
    font-size: 30px;
    padding: 35px;
    color: #393999;
    font-weight: 700;
}

.hiw_desc {
    font-family: 'Montserrat';
    color: #000000d4;
    /* font-size: 16px; */
    /* font-weight: 600; */
    padding: 20px;
    text-align: center;
}

.hiw_box {
    background-color: #E6E0FF;
    padding: 15px;
    border-radius: 10px;
    width: 400px;
}

.margin40{
    margin: 40px !important;
}

.home_hero{
    padding: 20px !important;
}

.textright_title {
    color: #393999;
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 600;
}
.whychooseus p{
    font-size: 18px;
}

.imagetextflex10{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 19px;
    margin-left: 7%;
    margin-right: 7%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.whychoose_sec0 {
    margin-bottom: 50px;
}

.whychooseus .imgleft{
    width: 45%;
}

.whychooseus .imgleft img{
    width: 420px;
}
/*
.whychooseus .txtright{
    width: 50%;
}*/


.whychooseus .txtright{
    width: 70%;
}


.infoblockflex {
    display: flex;
    flex-direction: row;
    align-items: start;
    background-color: azure;
    padding: 10px;
    border-radius: 10px;
}

.profilecompletion{
    width: 60%;
    padding-left: 20px;
    padding-right: 20px;
}

.todonotes{
    width: 40%;
    background-color: beige;
    padding: 5px;
    height: auto;
    border-radius: 10px;
}

.todonotes ul li{
    padding-left:5px;
}

.pinkbg i{
    color: red;
    padding-right: 10px;
}


.greentxt{
    color:green;
    font-size: 12px;
    font-weight: 600;
}

.redtxt{
    color:red;
    font-size: 12px;
    font-weight: 600;
}

.innerBgColor {
    background-color: #F4F8FB;
    margin-right: 7%;
    margin-left: 7%;
}

.wcdesc_normal{
    width: 100% !important;
}

.nav-item-profile {
    font-family: 'Montserrat';
    color: #212121;
    font-size: 13px;
    font-weight: 400;
    padding-top: 20px;
    padding-right: 15px;
    font-weight: 500;
}
.nav-item-profile span{
    color: #393999;
    background-color: #E6E0FF;
    border-radius: 25px;
    padding: 10px;
}

.progressflex{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding:5px;
}

.progress_item{
    width: 30%;
    color:#393999;
}

.bar-holder {
    width: 100%;
    background-color: #7f87ad;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin: 0 auto;
  }
  
  /* INDIVIDUAL BAR'S STYLING, SAME GOES WITH OTHER BARS */
  
  .bar-2 {
   
    background-color: #393999;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-weight: 800;
    line-height: 25px;
    margin-left: 10px;
  }
.yourcode{
    font-size: 16px;
    padding-left: 15px;
}
  .yourphone{
    font-size: 16px;
    padding-left: 15px;
  }

  .btnpadrright{
    margin-right: 10px;
  }

  .bottombuttons{
    padding: 15px;
  }

  .errorwarning{
    color: red;
    width: 100%;
    text-align: center;
    padding: 4px;
  }
  .successmessage{
    color: green;
    width: 100%;
    text-align: center;
    padding: 4px;
  }

  .agreement a{
    color:#393999;
  }

  .mainmenu_profile{
    font-size: 18px;
    padding:20px;
    width: 100%;
    font-weight: 600;
  }

  .smallslogan {
    color: #393999;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin-top: 60px;
    text-align: center;
    margin-bottom: 60px;
    margin-left:0px;
    margin-right: 0px;
}

.notesinblue{
    color: blue;
    padding: 11px;
    display: block;
    font-style: italic;
}
.suggestedjob .army-public-school6 {
    font-family: 'Montserrat';
    font-size: 13px;
    color: #000;
    line-height: 35px;
    font-weight: 500;
}

.suggestedjob .firstrow{
    display: flex;
    padding-bottom: 10px;
}
.suggestedjob .imgbox {
    float: left;
    width: 50px;
}

.suggestedjob .vuesaxbulklocation-icon6{
    width: 20px;
}

.suggestedjob .jobtype {
    line-height: 20px;
    font-size: 13px;
}

.suggestedjob .viewjob{
    display: inline-flex;
    margin-top: 10px;
}

.react-date-picker__inputGroup__input{
    width: 40px;
}

.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid gray;
    border-radius: 5px;
    font-family: 'Montserrat';
}

.jobtype {
    font-family: 'Montserrat';
    font-size: 13px;
}

.appliedjobs .jobtitle {
    color: #46474c;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    padding-top: 8px;
}
.appliedjobs .btn-small{
    display: inline-block;
    padding: 10px;
    font-size: 11px;
    margin-top: 5px;
}

.dateapplied{
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat';
    padding-top: 5px;
    padding-bottom: 5px;
}

.dateapplied span{
    color:#1A0A5B;
}

.job_status.applied{
    color: #1A0A5B;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 12px;
}

.job_status.green{
    color: green;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 12px;
}

.btn-green{
    background-color: green;
}

.avatar img {
    width: 50px;
    border-radius: 50px;
    height: 50px;
}

.wc-column {
    position: relative;
}

.pageloading{
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    z-index: 4;
    background-color: aliceblue;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0.8;
}

.pageloading img{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50%;
    margin-bottom: 50%;
}

.jobstatus {
    background-color: green;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    border-radius: 10px;
    margin-left: 10px;
    line-height: 32px;
}

.settings-menu-item span img {
    width: 20px;
    height: 20px;
}

.appliedjobs{
    box-shadow: 0px 1px 9px 0px #393999;
}
.smalllinks{
    float: right;
}
.btn-small-link{
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: underline;
}

.trendinglist_flex {
    display: flex;
    align-items: center;
    justify-content: start;
}

.blog_rating .react-simple-star-rating svg{
    width: 25px;
}

.rangeslider_profile{
    margin-top:50px;
}


.blog_main_content img:nth-child(even) { 
    justify-self: left;
    padding-right:10px;
    margin-left: 10px;
}

.blog_main_content img:nth-child(odd) { 
    justify-self: right;
    padding-left: 10px;
    margin-right: 10px;
}

.artcileblogimage {
    width: 350px;
}
.main-social-icons {
    bottom: -35px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
}
#blogbannerimg {
    float: right;
    margin: 0 0 0 15px;
}

.articleblock{
    position: relative;
}
.sharethisArticle{
    text-align: center;
}

.sharethisArticle button{
    border: 1px solid #393999;
    padding: 10px;
    border-radius: 20px;
    background-color: #393999;
    color: white;
    font-family: 'Montserrat';
}

.rws-header{
    position: relative !important;
}

.textright_title {
    color: #393999;
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 600;
}

.iconnumber_pin{
background-color: #393999;
width: auto;
padding: 15px;
border-radius: 5px;
line-height: 10px;
color: #fff;
position: absolute;
/* text-align: center; */
top: -14px;
/* margin-left: auto; */
/* margin-right: auto; */
/* width: 100%; */
left: auto;
font-size: 28px;
font-family: 'Montserrat';
}

/*
.hiw_box {
    background-color: #E6E0FF;
    padding: 5px;
    border-radius: 10px;
    width: 100%;
    position: relative;
}
*/

.hiw_box{
    background: rgb(2,0,36);
    background: linear-gradient(56deg, rgba(2,0,36,1) 0%, rgba(57,57,153,1) 35%, rgba(57,57,153,1) 100%);
    color: #fff;
    position: relative;
}


.hiw_desc_sm {
    font-family: 'Montserrat';
    color: #fff !important; /*393999*/
    font-size: 18px;
    font-weight: 600;
    padding: 20px;
    padding-top: 35px !important;
    padding-bottom: 5px !important;
}

.hiw_desc {
    font-family: 'Montserrat';
    color: #fff !important; /* #000000d4;*/
    /* font-size: 16px; */
    /* font-weight: 600; */
    padding: 20px;
    text-align: center;
    padding-bottom: 5px;
}

.section_columnboxes {
    display: flex;
    gap: 45px;
    margin-top: 45px;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
}


.whychooseus .imgleft img {
    width: 420px;
    height: 230px;
}

.flexin{
    display: flex;
    gap:10px;
    flex-direction: row;
    align-items: center;
}

.rightflex{
    width: 40%;
}
.leftflex{
    width: 60%;
}

.bannerimg_header{
    width: 100%;
}

.bigtext {
    color: #393999;
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 600;
    margin: 0 !important;
    text-align: left;
    margin-bottom: 10px !important;
}

.explorejobs{
   
    background-position: center;
    width: auto;
    position: relative;
    background-size: cover;
    padding: 20px;
}

.flex_section{
    margin-left: 10%;
    margin-right: 10%;
}

.headingwhite{
    color: #fff !important;
}
.marginleftright10{
    margin-left: 10%;
    margin-right: 10%;
}
/*
.whychooseus img {
    border-radius: 10px;
    width: 100% !important;
}*/
  
.whychooseus img {
    border-radius: 10px;
    width: 95%;
    height: 255px;
}

.whychooseus .imgleft img {
    /* width: 420px; */
    height: 230px;
}

.textright_title{
    padding-bottom: 15px;
}

.bannerimg_header_blog {
    width: 100%;
    margin: auto;
}

.rightflex100, .leftflex100{
    width: 100%;
}

.section_courses {
    background-color: #E6E0FF;
    padding: 35px;
    text-align: center;
    background-size: cover;
    background-position: bottom;
}

.toptext{
    background-position: center;
    background-size: cover;
    margin-left: 0px;
    margin-right: 0px;
    height: 400px;
}

.toptext_para{
    width: 37%;
    margin-left: 10%;
}

.blogbghome{
    background-position: center;
    background-size: contain;
    height: 250px;
}

.toptext_para_blog {
    width: 100%;
}

.headingblue{
    color:#393999;
}

.hero_head_blog{
    font-family: 'Montserrat';
    font-size: 30px;
    padding: 35px;
    color: #393999;
    font-weight: 600;
}
.hero_desc_blog {
    padding-left: 35px;
    font-family: 'Montserrat';
    font-size: 16px;
    color: #000000;
    background-color: #ffff;
    border-radius: 20px;
    padding: 20px;
    text-align: left;
}

.heroleft_blog_content_section {
    width: 100%;
}

.ads-section{
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.hero_head_left{
    text-align: left;
    padding-left: 5px !important;
}

.hero_head_right{
    text-align: right;
    padding-right: 5px !important;
}

.bloginfo_img_big img{
    border-radius: 10px;
}

.trending_title_featured{
    text-align: left;
    padding-top: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat';
}

.bloginfo_text_featured{
    width: 100%;
}


.externalimgthumb{
        width: 54%;
}

.externalimgthumb img{
    border-radius: 10px;
}

.postwaitingmessage{
    font-size: 18px !important;
    font-weight: 600 !important;
}

.flexequalblk{
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
}

.imgleft_50{
    width: 50%;
}

.txtright_50{
    width: 50%;
}

.postwaitingmessage p{
    line-height: 30px;
    margin-bottom: 20px;
}

.centered-click-here {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.blockeditemins{
    display: inline-block;
    background-color: #393999;
    padding: 10px;
    border-radius: 10px;
    color: #ffff;
    margin-top: 10px;
    margin-right: 10px;
}
.boxdesc ul{
    list-style: disc;
    padding-left:20px;
}

.boxdesc{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat';
}

.profilewarning {
    color: orange;
    font-weight: 600;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    animation: blinker 3s linear infinite;
}
.starnotes{
font-size: 14px;
padding-left: 20px;
padding-top: 10px;
}
.linknotes{
    padding-left: 20px;
    padding-top: 10px;
    font-size: 14px;
}
.linknotes a{
    font-size: 14px;
    color:#393999;
}

.alreadyregfleft {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
}

.alreadyregfleft a{
    color:#393999;
    font-weight: 800;
}

.pushleft20{
    padding-right: 10px !important;
}

.blog_au_name{
    padding-left:20px;
    font-weight: 600;
    font-size: 12px;
}

.blog_au_date{
    float: right;
    font-size: 12px;
    font-weight: 600;
}

.tab_title{
    padding: 15px;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-weight: 500;
    border:1px solid #e6e0ff;
    color: #000;
    opacity: 0.6;
    cursor: pointer;
  }
  
  .tabactive{
      background-color: #e6e0ff;
      color: #393999;
      opacity: 1.0;
      font-weight: 600;
  }
  
  .maintabs_popup_centered{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 20px;
  }
  
  .mainhead_popup_centered{
    color: #393999;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 15px;
  }
  
  .main_popup_content{
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .jobseeker{
    padding:5px;
  }
  .employer{
    padding:5px;
  }
  
  .popup_label_holder{
    padding-top: 10px;
  }
  
  .popup_input_holder{
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .popup_input_holder input[type="text"]{
    background-color: #e6e0ff;
    border-radius: 5px;
    width: 100%;
    border: none;
  }
  
  .popup_input_holder input[type="number"]{
    background-color: #e6e0ff;
    border-radius: 5px;
    width: 100%;
    border: none;
  }
  
  .input{
    background-color: #e6e0ff;
    border-radius: 5px;
    width: 100%;
    border: none;
  }
  .popup_input_holder select{
    background-color: #e6e0ff;
    border-radius: 5px;
    width: 100%;
    border: none;
  }
  
  .popup_label_holder label{
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #000;
    opacity: 0.6;
  }
  
  
  .popup_submit_holder .btn {
    margin-top: 30px;
    background: #471BF5;
    padding: 12px;
    text-align: center;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: bold;
  }
  
  .popup_submit_holder .btnlinkwhite {
    color: #fff;
    cursor: pointer;
  }
  
  
  .popup_submit_holder{
    padding-top: 10px;
  }
  
  .popup_close_btn {
    position: initial;
    display: block;
    text-align: right;
    width: 100%;
    float: right;
    font-family: 'Montserrat';
    cursor: pointer;
  }
  
  .text-danger{
    font-size: 13px;
    color:red;
    padding-top:2px;
  }
  
  .padtop15{
    padding-top: 15px;
  }

.adimg img{
    width: 980px !important;
}
 
.adimg{
    text-align: center;
    width: 980px;
    margin: auto;
}

.inner_header_blog h2{
    font-family: 'Montserrat';
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color:#fff;
    padding-top: 80px;
}

.flexcolumn{
    display: flex;
    flex-direction: column;
}

#textbtmads{
    position: absolute;
    top: 70%;
    width: 100%;
}
@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

.floatlefttray {
    background-color: #fff;
    border-radius: 0 10px 10px 0;
    box-shadow: 5px 3px 5px #393999;
    display: block;
    margin: 0;
    padding: 10px 0;
    position: fixed;
    top: 50%;
    z-index: 999;
}  

.floatlefttray img {
    height: 22px;
    margin: auto 12px;
}

.morebloghead{
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: #471BF5;
    padding-bottom: 20px;

}

.centerebtn {
    text-align: center;
    display: inline-flex;
    align-items: normal;
    width: 100%;
    justify-content: center;
}
.comments{
    margin-bottom: 70px;
}

.anamelabel{
    display: block;
    text-decoration: underline;
}

.blogauthor img {
    border-radius: 65px;
    width: 400px;
}

.displayflex{
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.authorname, .authordesc , .authorsocial{
    padding:10px;
}

.blogauthorsection{
    background-color: lightsteelblue;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
}

.otherr_blogs{
    margin-top: 90px;
}

.displayflexed{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.author_profile_row{
    font-size: 15px;
    text-transform: capitalize;
}

.inline-item{
    display: inline-block;
    padding:10px;
    margin:10px;
    width: 280px;
}

.moreblogg_items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

.moreblog_title{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat';
    padding-top: 10px;
}

.authorsocial ul li a .fab {
    width: 100%;
    background: linear-gradient(0deg,#fff,#ddd);
    border-radius: 50%;
    color: black;
    display: block;
    font-size: 18px;
    height: 100%;
    line-height: 23px;
    transition: .5s;
    font-style: normal;
}

.fa-facebook {
    background: #3b5998;
    color: #fff;
}
 
.fab, .far {
    font-weight: 400;
}
 
.fab {
    font-family: 'FontAwesome';
}
 
.authorsocial ul li a {
    background: linear-gradient(0deg,#ddd,#fff);
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0,0,0,.3);
    box-sizing: border-box;
    display: block;
    height: 35px;
    margin: 0 10px;
    padding: 6px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: .5s;
    width: 35px;
}

.authorsocial ul {
    display: inline-flex;
    margin: 0;
    padding: 0;
    -ms-transform: translate(-50%,-50%);
}

.blogauthorsection {
    background-color: #e6e0ff;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
}

.moreblogg_items img{
    border-radius: 10px;
}

  .articleblock{
     padding-top: 10px;
  }

  .articleblock ul{
    list-style: disc;
    padding-left:15px;

  }
  
  .articleblock ul li a{
    display: inline-block;
    color:#393999;
  }
  
  .authorprofileblock img {
    border-radius: 65px;
    width: 100px;
  }

  .blog_header {
    background-repeat: repeat;
    background-size: auto;
    height: 120px;
    position: relative;
    background-color: beige;
}

.post_in_category {
    align-items: center;
    display: flex;
    gap: 30px;
    justify-content: normal;
    flex-wrap: wrap;
}

.title_small{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
    -webkit-box-orient: vertical;
}

.cursorpointer{
  cursor: pointer;
}

.messages{
  font-weight: 500;
}

.success{
  color:green;
}
.error{
  color:red;
}

.info{
    color:#000;
}

.hey-there {
  font-size: 20px;
}

.slabel{
  font-size: 14px;
}

.terms-privacy-policy{
  cursor: pointer;
}

.agreement{
    padding-top: 10px;
}