


/* (1366x768) WXGA Display */
@media  screen and (max-width: 1366px) {
    /* insert styles here */

    .tablike {
        cursor: pointer;
        font-family: 'Montserrat';
        background-color: #ffffff;
        padding: 15px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        font-weight: 600;
        font-size: 12px;
    }

    .activetab {
        cursor: pointer;
        font-family: 'Montserrat';
        background-color: #471BF5;
        padding: 15px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }

    .borderedbox {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 10px;
        padding-left: 10px;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        margin: 0px;
        margin-bottom: 15px;
    }

    .nav-item {
        font-family: 'Montserrat';
        color: #212121;
        font-size: 12px;
        font-weight: 400;
        padding-top: 7px;
        padding-right: 50px;
    }

    .nav-link {
        line-height: 20px;
        font-weight: 600;
        font-size: 12px;
    }

    .component-26-child {
        height: 60px;
    }

    .profilesearch {
        width: 100%;
        height: 60px;
        border-radius: 10px 0px 0px 10px;
    }
 
    .savedfilters {
        padding: 5px;
    }
    
    .semiboldtexted {
        font-weight: 600;
        color: #000;
        text-align: center;
    }
    .font20 {
        font-family: 'Montserrat';
        font-size: 12px;
        line-height: 30px;
    }
    .vuesaxlinearfilter-square-icon{
        width: 40px;
        height: 40px;
    }

    #profile_top {
        height: 80px;
    }

    .h3title {
        font-family: 'Montserrat';
        font-size: 16px;
        color: #393999;
        font-weight: 700;
        padding: 15px;
        text-align: left;
    }
    .texted {
        font-size: 13px;
    }

    .unborderedboxnopad {
        border-radius: 6px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 0px;
    }

    .optioneachrow {
        color: #46474c;
        font-weight: 600;
        font-size: 13px;
        font-family: 'Montserrat';
    }

    input[type="checkbox"] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: #393999;
        width: 15px;
        height: 15px;
        border: 0.15em solid #393999;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
    }

    .left_searchbox {
        width: 85%;
        border: none;
        background-color: #d9d9d9;
        line-height: 15px;
        font-size: 13px;
    }

    .army-public-school6 {
        font-family: 'Montserrat';
        font-size: 16px;
        color: #000;
        line-height: 30px;
    }

    .job-card-child{
        width: 100px;
    }
    .vuesaxbulklocation-icon6 {
        float: left;
        vertical-align: middle;
        width: 16px;
    }
    .jobtype{
        font-size: 13px;
    }

    .jobtitle {
        color: #46474c;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: 600;
        padding-top: 15px;
    }

    .jobdesc {
        color: #46474c;
        font-family: 'Montserrat';
        font-size: 13px;
        padding-top: 15px;
    }

    .thirdrow{
        display: flex;
    }

    .applybtnlg {
        width: 100%;
        font-size: 13px;
        line-height: 13px;
    }
    .union-parent{
        display: flex;
    }
    .union-icon {
        float: none;
        padding-left: 0px;
        width: 13px;
    }

    .share-job {
        line-height: 13px;
        font-size: 13px;
        padding-left: 13px;
        display: flex;
    }

    .share-button {
        cursor: pointer;
        width: 30%;
    }
    .expandbtn {
        color: #1a0a5b;
        font-family: 'Montserrat';
        font-size: 13px;
        padding: 10px;
        cursor: pointer;
        text-align: right;
        line-height: 22px;
        padding-right: 5px;
    }
    .content-section{
        margin:15px;
    }

    .box_title_lg {
        color: #1A0A5B;
        font-size: 15px;
        font-weight: 700;
        font-family: 'Montserrat';
    }
    .notificationheader {
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .box_desc_sm {
        font-family: 'Montserrat';
        font-size: 12px;
        margin-top: 10px;
    }

    .viewjob_footer_btns{
        display: flex;
    }

    .footerhead {
        font-family: 'Montserrat';
        font-size: 14px;
        color: #393999;
        font-weight: 700;
        padding: 15px;
        text-align: left;
    }

    .footerlinks li {
        font-family: 'Montserrat';
        color: #393999;
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .wc_logo img {
        width: 140px;
    }
    .wc_footer_subscribe {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
        color: #471BF5;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .copyright {
        text-align: center;
        padding: 15px;
        font-family: 'Montserrat';
        color: #393999;
        font-size: 12px;
    }

    .menubutton .nav-item {
        padding-top: 10px;
        padding-right: 0px;
    }

    .avatar img {
        width: 35px;
    }

    .dropdown-content a {
        color: black;
        padding: 7px 15px;
        text-decoration: none;
        display: block;
        font-size: 12px;
    }

    .tag {
        font-size: 12px;
    }
    .courseinfo_title {
        font-family: 'Montserrat';
        font-size: 16px;
        color: #000;
        font-weight: 600;
        line-height: 40px;
    }

    .courseinfo_ins {
        font-family: 'Montserrat';
        font-size: 13px;
        color: #000;
        line-height: 10px;
    }

    .courseicon {
        width: 100%;
    }

    .courseinfo_desc {
        font-family: 'Montserrat';
        font-size: 13px;
        color: #000;
        line-height: 25px;
    }
    .coursedetailtitle {
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 600;
        color: #393999;
        line-height: 25px;
    }
    .courseduration {
        font-family: 'Montserrat';
        font-size: 13px;
        line-height: 25px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .coursecertificates {
        font-family: 'Montserrat';
        font-size: 13px;
        line-height: 25px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .coursetypes li {
        font-size: 13px;
        font-family: 'Montserrat';
        line-height: 35px;
    }

    .bloginfo h2 {
        font-size: 30px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #fff;
        line-height: 60px;
    }

    .blogshort {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #fff;
        line-height: 36px;
    }

    .title_small {
        padding: 13px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 13px;
        padding-top: 3px;
    }

    .desc_small {
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 12px;
    }

    .plainlink {
        padding: 10px;
        border: 1px solid #471BF5;
        border-radius: 10px;
        font-size: 12px;
        color: #471BF5;
    }

    .trending_title {
        padding: 20px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 13px;
        padding-bottom: 10px;
    }

    .trending_desc_small {
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 12px;
    }


    .trendingitemrow {
        margin-bottom: 10px;
    }

    .slideritems {
        display: flex;
        gap: 60px;
        margin: 40px;
    }

    .featurdblog_title {
        font-size: 20px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #FFFFFF;
        padding-bottom: 20px;
    }

    .featuredblog_desc_small {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #FFFFFF;
        padding-bottom: 20px;
    }

    .heart {
        fill: red;
        position: relative;
        top: 0px;
        width: 14px;
        animation: pulse 1s ease infinite;
        display: inline-block;
    }

    .curated_head {
        font-size: 14px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #471BF5;
        padding-bottom: 6px;
    }
    .blog_slider_section{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .blog_s_head{
        font-size: 14px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #471BF5;
        padding-bottom: 6px;
        width: 100%;
    }

    .viewall{
        font-size: 14px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #471BF5;
        padding-bottom: 6px;
        width: 100%;
        text-align: end;
        cursor: pointer;
    }

    .featured_blog_info {
        width: 100%;
        margin-top: 250px;
        padding-left: 40px;
    }

    .editor-title-row .label {
        font-family: 'Montserrat';
        color: rgb(90, 87, 87);
        font-size: 13px;
        line-height: 40px;
        width: 15%;
    }
    .hero_head {
        font-family: 'Montserrat';
        font-size: 40px;
        padding: 35px;
        color: #393999;
        font-weight: 700;
    }

    .hero_desc {
        padding-left: 35px;
        font-family: 'Montserrat';
        font-size: 16px;
        color: #000000;
    
    }

    .purplebox_title {
        padding: 10px;
        border-radius: 10px;
        background-color: #D0BDF8;
        font-size: 13px;
        font-weight: 600;
        color: #393999;
        text-align: center;
    }

    .purplebox {
        background-color: #E7F2F5;
        padding: 15px;
        width: 371px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .pj_job_institute {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 10px;
        font-family: 'Montserrat';
    }
    .pj_job_title {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
        font-family: 'Montserrat';
    }
    
}
 
/* (769x1024) tablets */

@media (min-width: 650px) and (max-width: 1279px) {
    /* insert styles here */

    #overlayloginrequire{
        bottom: 80px;
    }

    .blog_main_content img {
        height: auto;
        width: 250px;
    }
    
    .blog_main_header {
        background-color: #333333;
        height: auto;
    }

    .blog_main_title {
        text-align: center;
        font-size: 35px;
        margin-top: 10px;
        font-family: 'Montserrat';
        font-weight: 600;
    }
    .inner_header_blog {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .blog_main_content {
        width: 100%; 
        margin-left: auto;
        margin-right: auto;
        text-align: justify;
    }

   
    .blog_main_footer {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .blog_main_footer {
        padding: 10px;
        margin: auto !important;
    }

    .textareainputcssblue {
        border: 1px solid #471BF5;
        border-radius: 10px;
        width: 100%;
    }

    .textareacss {
        background-color: #F4F4F4 !important;
        border-radius: 10px;
        width: 100%;
    }

    .sendbtn {
        margin-top: 10px;
        display: grid;
    }

    .mainvh {
        height: 100%;
        background-color: #EFEFEF;
        /* display: flex; */
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .innermainblock {
        height: 100%;
        padding: 10px;
        width: 100%;
    }

    .ratings {
        padding-top: 10px;
        padding-bottom: 50px;
        padding-left: 14px;
        padding-right: 14px;

    }

    .search_icon_m span{
        top: 12px;
        position: relative;
    }

    .footer_menu {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: row;
        padding: 10px;
        padding-top: 20px;
        gap: 10px;
    }

    .search_icon_m img {
        position: absolute;
        bottom: 30px;
        width: 100%;
        border: 1px solid lightgrey;
        border-radius: 50px;
        background-color: lightgray;
        padding: 5px;
    }

    .c_name span {
        width: 100%;
        font-size: 13px;
        font-weight: normal;
        /* padding-left: 141px; */
        display: inline-block;
    }

    .newcourses{
        padding-top: 20px;
    }
    .listtitle{
        font-family: 'Montserrat';
        color: #000;
        font-weight: 600;
        font-size: 16px;
        padding: 20px;
        padding-bottom: 0px;
    }

    .courselist_mobile{
        /*display: inline-flex; gap: 24px;*/
        display:block;
        padding: 20px;
    }
    
    .courserow_mobile {
        width: auto!important;
        padding: 5px;
        margin: 0px;
        margin-right: 0px;
        display: inline-block;
    }

    .courseinfo {
        width: 100%;
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 4px;
    }

    .courseinfo_title {
        font-family: 'Montserrat';
        font-size: 13px;
        color: #000;
        font-weight: 600;
        line-height: 18px;
    }

    .courseinfo_ins {
        font-family: 'Montserrat';
        font-size: 12px;
        color: #000;
        line-height: 14px;
    }

    .inner_block_mobile{
        margin-top: 30px;
        margin-bottom: 100px;
    }   

    .applybutton {
        width: auto;
        display: flex;
        text-align: end;
    }

    .share-button {
        cursor: pointer;
        width: auto;
        text-align: start;
    }

    .profile_topsection{
        display: block;
        align-items: center;
        padding-top: 30px;
    }

    .profile_buttons {
        width: 100%;
        padding-right: 20px;
        text-align: right;
        padding-bottom: 20px;
    }

    .experience_section {
        background-color: #ffffff;
        border-radius: 15px;
        padding: 20px !important;
        margin: 0px !important;
        margin-bottom: 20px !important;
    }

    .spacer{
        
        height: 100px;
    }

    /* edit profile */
    .profilephoto img {
        width: 150px;
        float: left;
        border-radius: 75px;
        margin-bottom: 20px;
    }

    .profilephoto {
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }

    .profilephoto input{
        margin-left: 50%;
        margin-right: 20%;
    }

    .phonenfield {
        width: 72%;
        border-radius: 0 4px 4px 0px;
    }
    
    .home_hero{
        display: inline-block !important;
    }

    .hero_head {
        font-family: 'Montserrat';
        font-size: 24px;
        padding: 12px;
        color: #393999;
        font-weight: 700;
    }

    .hero_desc {
        padding-left: 10px;
        font-family: 'Montserrat';
        font-size: 16px;
        color: #000000;
 
        padding-right: 10px;
    }

    .hero_search {
        padding: 15px;
        background-color: #fff;
        margin-left: 0px;
        border-radius: 10px;
        margin-top: 20px;
    }
    .btn-main-full {
        font-family: 'Montserrat';
        background-color: #471BF5;
        padding: 10px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        width: 100%;
        line-height: 15px;
    }

    .section_head_desc {
        color: #393999;
        font-size: 25px;
        font-family: 'Montserrat';
        font-weight: 600;
        margin: 20px;
        text-align: center;
    }
    
    .slick-dots{
        margin-bottom: 17px;
    }

    .hiw_box {
        background-color: #E6E0FF;
        padding: 30px;
        border-radius: 10px;
        width: 300px;
        margin-bottom: 20px;
    }
    
    .section_columnboxes {
        display: block;
        margin-top: 45px;
    }

    .btn-main-big {
        display: inline;
        margin: 20px;
        font-family: 'Montserrat';
        background-color: #471BF5;
        padding: 10px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .sectionbutton {
        padding: 0px;
        display: inline-flex !important;
    }

    .white{
        background-color: #ffffff;
    }

    .component-26-child {
        height: 89px !important;
    }
    
    .filtericon{
        height: 40px;
    }

    .profilesearch {
        width: 100%;
        height: 89px;
        border-radius: 10px 0px 0px 10px;
    }

    .tablike {
        cursor: pointer;
        font-family: 'Montserrat';
        background-color: #ffffff;
        padding: 15px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }

    .savefilter {
        display: flex;
        padding: 10px;
        margin: 10px;
        align-items: center;
    }

    .job-card-item{
        width: 100px;
    }

    .activetab {
        cursor: pointer;
        font-family: 'Montserrat';
        background-color: #471BF5;
        padding: 15px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }

    .notetoothers{
        font-size: 12px;
    }

    .othercourselist_mobile{
        padding: 20px;
        height: 250px;
        overflow-y: scroll;
    }

    /*why choose us*/
    .whychooseus{
        width: 100%;
    }

    .wcdesc_normal{
        width: 100%;
    }
    .imagetextflex {
        display: flex;
        margin-top: 100px;
        margin-bottom: 100px;
        gap: 40px;
        align-items: center;
       
    }

    .txtright{
        width: 100%;
        text-align: center;
    }

    .advisorysection{
        width: 100%;
        position: relative;
    }
    .buildingbg {
        width: 100%;
        height: 444px;
    }

    #overlayadvisory {
        position: absolute;
        width: 100%;
        height: 90%;
        top: 375px;
        left: 0;
        /* right: 0; */
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 2;
        cursor: pointer;
    }
    
    .tiles {
        display: flex;
        gap: 40px;
        width: 100%;
        flex-direction: row;
        align-items: normal;
        margin-bottom: 40px;
    }

    .home_hero{
        padding: 30px !important;
        font-size: 30px !important;
        text-align: center !important;
    }

    .hero_desc{
        text-align: justify;
    }

    .hiw_desc {
        font-family: 'Montserrat';
        color: #000000;
        font-size: 13px;
        /* font-weight: 600; */
        padding: 5px;
        text-align: justify;
    }

    .articleswc{
        background-color: #E6E0FF;
        padding: 10px;
    }

    .whychooseus_m{
        background-color: #ffffff;
        padding:10px;
        padding-top: 60px;
    }

    .wctitle_large {
        color: #393999;
        font-family: 'Montserrat';
        font-size: 21px;
        font-weight: 700;
        padding-bottom: 25px;
        text-align: left;
        padding-left: 15px;
    }

    .whychoose_content{
        padding: 15px;
        text-align: center;
    }

    .wcdesc_normal{
    padding: 15px;
    font-size: 16px;
    text-align: justify;
    }

    .hiw_box {
        background-color: #E6E0FF;
        padding: 30px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 20px;
    }

    .home_hero {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 40px 40px;
        background-position-x: 380px;
        background-position-y: 70px;
    }

    .section_howitworks{
        background-repeat: no-repeat;
        background-size: 140px; 
        background-position: left;
        padding-bottom: 80px;
    }

    .section_howitworks {
        background-color: #ffffff;
        padding: 35px;
        text-align: center;
        padding-bottom: 80px !important;
    }

    .trending_title_white {
        font-family: 'Montserrat';
        font-size: 21px;
         color: #000; 
        text-align: left;
        padding-left: 13px;
    }

    .fb_img{
        padding: 10px;
    }
    .fb_img img{
        border-radius: 10px;
        width: 100%;
    }

    .featured_blog {
        padding:60px;
    }
    
    .trending_desc_small_white {
        font-family: 'Montserrat';
        color: #000; 
        text-align: left;
        padding-left: 16px;
        padding-top: 10px;
    }

 
    /*
    .trendinglist {
        padding: 20px;
        background-color: white;
        margin: 10px;
        border-radius: 10px;
        margin-top: 40px;
    }
    */
    .trendinglist {
              
        margin: 10px;
        border-radius: 10px;
        margin-top: 40px;
    }
    .fleximgblog{
        width: 25%;
    }
    .flexcontblog{
        width: 75%;
    }
    .trending_title {
        padding:0px; 
        font-family: 'Montserrat';
        font-weight: normal;
        font-size: 16px !important;
        padding-bottom: 10px;
        background-color: none !important;
    }
    .trending_title_white span {
        background-color: #ffffff;
        padding: 5px;
        margin-left: 15px;
        font-size: 12px;
        border-radius: 5px;
    }

    .ul_othercourses li{
        width: auto;
    }

    .closeit{
    border-radius: 50px;
    background-color: #fff;
    width: 30px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    line-height: 30px;
    padding-left: 10px;
    box-shadow: 0px 1px 1px 0px #000000;
    font-size: 15px;
    right: 15px;
    position: absolute;
    }
    .modal-title{
        width: 100%;
    }
    .coursename_d{
        font-size: 23px;
    }

    .othercourselist_mobile {
        padding: 20px;
        height: 290px;
        overflow-y: scroll;
    }

    .watermark {
        position: fixed;
        z-index: 8;
        margin-top: -7%;
        opacity: 1;
        top: 50%;
    }

    .textright_title {
        color: #393999;
        font-family: 'Montserrat';
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        padding-bottom: 10px;
        text-align: left;
    }


    .wcdesc_normal {
        padding: 15px;
        text-align: justify;
        margin-bottom: 30px;
    }

    .section_columnboxes {
        display: flex !important;
        margin-top: 45px;
    }

    .rightposition {
        position: absolute;
        float: right;
        z-index: 3;
        bottom: 0px;
        top: 20px;
        right: 60px;
    }

    .trending_title {
        padding-top: 0px;
        font-family: 'Montserrat';
        font-weight: normal;
        font-size: 13px !important;
        padding-bottom: 0px;
        padding-left: 0px;
    }
    .fleximgblog{
        width: 100%;
    }
 
    .flexcontblog{
        width: 100%;
    }

    .trendingitemrowview{
        display: block;
        box-shadow: 0px 6px 6px -5px #000;
        padding: 0px;
        margin: 10px;
    }

    .blogitemrow{
        position: relative;
        min-height: 220px;
    }

    .flexcontblog{
        height: 50px;
        background-color: #fff;
    }
    .articleswc {
        background-color: #E6E0FF;
        padding: 10px;
        padding-bottom: 70px;
    }

    .leveldropdowns{
        padding-left: 30px;
        display: flex;
        padding-right: 30px;
    }
    .positionbox {
        padding: 20px;
        padding-top: 10px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        display: inline;
    }
    .profile_buttons {
        width: 100%;
        padding: 10px;
        display: grid;
    }

    .watermark {
        position: fixed;
        z-index: 8;
        margin-top: -7%;
        opacity: 1;
        top: 50%;
        left: 5%;
    }

   
    .bloginfo {
        position: unset;
        padding: 20px;
        padding-top: 70px;
    }

    .bloginfo h2 {
        font-size: 20px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #fff;
        line-height: 30px;
    }

    .slidings{
        width: auto;
        overflow-y: scroll;
        display: flex;
        scroll-behavior: smooth;
        scrollbar-color: red yellow;
    }

    .slidings {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    }
    .slidings::-webkit-scrollbar-thumb {
        background-color: black;
    }


    .mainblock{
        background-color: #ffff;
        padding: 10px;
    }

    .category_tags {
        margin: 10px;
        padding: 10px;
    }
    .categorizedblogs .tag {
        width: auto;
        max-width: 200px;
        min-width: 200px;
    }

    .slider{
        padding:10px;
        margin:10px;
    }

    .categorizedblogs .bloglink{
        display: block;
        text-align: center;
        margin-left:0px;
    }
    .blogimg img{
        width: 100%;
    }

    .slick-list{
        padding-bottom: 30px;
    }

    .whitebg{
        background-color: #ffffff;
    }

    .trendingnow {
        width: 100%;
        height: auto;
        padding: 5px;
    }
    .trendinglist {
        margin: 10px;
        border-radius: 10px;
        /* margin-top: 40px; */
        padding: 10px;
    }

    .bloginfo_img {
        width: 35%;
    }
    .bloginfo_img img{
        width: 100%;
    }

    .bloginfo_text{
        width: 65%;
    }
    .flexitem {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .trendingitemrow {
        margin-bottom: 20px;
    }

    .trending_extra a {
        /* padding-left: 20px; */
        font-size: 12px;
        color: #FFFFFF;
        background-color: #471BF5 ;
    }
    .featuredblog{
        border-radius: 0;
        height: auto;
    }
    .borderedradius {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
    .curated_list {
        width: 100%;
        padding: 10px;
        margin: 10px;
    }

    footer {
        bottom: 0px;
        position: fixed;
        width: 100%;
        box-shadow: 0px 37px 15px 30px #000;
    }

    .filterbtns{
        display: flex;
    }

    .fi {
        width: 100%;
        padding: 10px;
        background-color: #fff;
        margin: 10px;
        border-radius: 10px;
        box-shadow: 0px 4px 15px 0px;
    }

    .filteropen{
        font-size: 13px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #471BF5;
    }

    .filterblock{
        padding-bottom: 10px;
    }

    .savefilter span{
        width: 100%;
        text-align: center;
    }

    .text-right{
        text-align: right;
    }
    
    .text-left{
        text-align: left;
    }

    .bookmarking img {
        width: 40px;
        padding-top: 5px;
        height: 40px;
    }

    .savefiltertxt {
        padding-left: 5px;
        font-family: 'Montserrat';
        text-transform: revert;
        font-size: 14px;
    }

    .frame-group {
        flex-direction: column;
        padding: 25px 25px !important;
        gap: 37px !important;
    }

    .blogitem{
 
        padding-bottom: 20px;
    }
    .categorylist {
        height: 95px;
        padding: 10px;
    }

    .disflex{
        display:flex;
    }
    .ScrollbarsCustom-ThumbX{
        cursor: pointer;
        border-radius: 4px;
        background: #471BF5 !important;
        height: 100%;
        width: 147.84px;
        transform: translateX(0px);
    }

    .mainvh{
        padding-top: 70px;
    }

    .centered{
        width: 100%;
        text-align: center;
    }

    .toptext{
        background: #F4F8FB;
        padding: 10px;
        /* margin: 13px; */
        display: flex;
        /*background-position: center;*/
        background-position: -210px 11px;
        background-repeat: no-repeat;
        background-size: cover;
        /* 
        margin-left: 5%;
        margin-right: 5%;
        */
    }

    .innerBgColor {
        background-color: #f4f8fb;
        margin-left: 0%;
        margin-right: 0%;
    }

    .toptext_para p {
        font-size: 16px;
        line-height: 25px;
    }

    .hero_head {
        font-family: 'Montserrat';
        font-size: 27px;
        padding: 35px;
        color: #fff;
        font-weight: 600;
    }


    .hero_desc {
        padding-left: 35px;
        font-family: 'Montserrat';
        font-size: 16px;
        color: #000000;
        margin: 20px;
    }
    
    .bigtext {
        color: #393999;
        font-size: 27px !important;
        font-family: 'Montserrat';
        font-weight: 600;
        margin: 20px;
        text-align: center;
    }

    .toptext_para{
        font-size: 18px;
        font-weight: normal;
        text-align:left;
        width: 40% !important;
        margin-left: 7% !important;
    }

    .home_hero{
        position: relative;
        margin:0;
    }
    .glitter img {
        width: 50px;
        position: absolute;
        animation: zoom-in-zoom-out 1s ease infinite;
        top: 0px;
    }

    .spacer70 {
        height: 70px !important;
    }

    .bloginfo_img img{
        width: 100%;
        height: auto;
    }

    .pad20{
        margin: 10px;
        padding: 10px;
        min-height: 235px;
    }

    .hiw_box {
        height: 285px;
        display: inline-block;
    }

    .already-have-an-account{
        font-size: 14px;
    }

    .sign-in1{
        font-size: 14px;
    }

    .fullwidth{
        width: 100%;
    }
    .by-clicking-create-container{
        font-size: 14px;
    }
    .by-clicking-create-container span{
        font-size: 14px;
    }

    .whychoose_sec1 {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0px 173px;
        /* background-position: 425px 214px; */
    }
}

.tabletlandscape{
    display: none;
}

.mobilelayout{
    display: block;
}
/* potrati tablet */
@media (min-width: 880px) and (max-width: 981px) {
        
}



/* lanscape tablet */
@media (min-width: 1324px) and (max-width: 1600px) {

    .blogitem {
        width: 200px;
        padding-left: 60px;
    }

        .tabletlandscape{
            display: block;
        }
        .mobilelayout{
            display: none;
        }
        .section_columnboxes {
            display: flex !important;
        }

        .whychooseus {
            width: auto;
        }

        .imagetextflex{
            flex-direction: row;
        }

        .trending_title_white {
            font-family: 'Montserrat';
            font-size: 30px;
            color: #ffff;
            text-align: left;
        }

        .trending_desc_small_white {
            font-family: 'Montserrat';
            color: #ffff;
            text-align: left;
        }

        .home_hero {
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 40px 40px;
            background-position-x: 550px;
            background-position-y: 260px;
        }


        .blog_header {
            height: 350px !important;
            position: relative;
            background-image: url(https://wiseconnect.bewise.in/static/media/blogclassroom.52d1d37….png),linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
            background-blend-mode: overlay;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .bloginfo {
            position: unset;
            padding: 20px;
            padding-top: 190px;
        }

        .blog_middle {
            padding-left: 50px;
            padding-right: 50px;
        }
        .tag {
            font-size: 13px;
        }

        .title_small {
            padding: 20px;
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 13px;
        }

        .plainlink {
            padding: 8px;
            border: 1px solid #471BF5;
            border-radius: 10px;
            font-size: 13px;
            color: #471BF5;
        }

        .courserow {
            display: flex;
            margin-bottom: 30px;
            cursor: pointer;
            align-items: center;
            gap: 10px;
        }

        .extblog_title {
            font-family: 'Montserrat';
            font-size: 13px;
            color: #000;
            font-weight: 600;
            line-height: 25px;
            padding-top: 5px;
            text-transform: capitalize;
        }

        .plainlink_small {
            padding: 7px;
            border: 1px solid #471BF5;
            border-radius: 10px;
            font-size: 13px;
            color: #471BF5;
        }

        .tiles {
            display: flex;
            gap: 40px;
            width: 100%;
            flex-direction: row;
            align-items: normal;
            margin-bottom: 40px;
        }
        .buildingbg {
            width: 100%;
            height: 500px;
        }

        .watermark {
            position: absolute;
            z-index: 8;
            left: 38%;
            /* margin-top: -7%; */
            /* opacity: 1; */
            top: 120%;
        }

        .borderedbox {
            background-color: #ffffff;
            border-radius: 6px;
            padding: 10px;
            padding-left: 20px;
            padding-top: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            margin: 5px;
        }

        .component-26-child {
            height: 70px;
        }

        .profilesearch {
            width: 100%;
            height: 70px;
            border-radius: 10px 0px 0px 10px;
        }
        .vuesaxlinearfilter-square-icon{
            width: 40px;
        }

        .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            width: 100%;
            padding-right: var(--bs-gutter-x,.75rem);
            padding-left: var(--bs-gutter-x,.75rem);
            margin-right: auto;
            margin-left: auto;
            padding-bottom: 15px;
        }

        .profilephoto img {
            width: 125px;
            float: left;
            border-radius: 65px;
            margin-right: 25px;
            height: 125px;
        }

        .formgroup {
            display: block;
            clear: both;
            margin-top: 5px;
            margin-bottom: 12px;
        }

        .profilephoto span {
            margin-top: 12px;
            display: block;
            font-size: 13px;
        }

        .profilephoto {
            display: block;
        }
        .profilephoto input{
            margin:auto;
        }    
        .profile_middle{
            display: block;
            margin-top: 40px;
        }

        .courseimg img {
            width: 600px;
            height: auto;
        }

        .courseicon {
            width: 80px;
        }

        .course_h3title {
            font-family: 'Montserrat';
            font-size: 30px;
            color: #fff;
            font-weight: 700;
            padding: 30px;
            padding-bottom: 10px;
            text-align: left;
        }

        .course_desc {
            margin-left: 30px;
            margin-right: 30px;
            margin-bottom: 30px;
            font-family: 'Montserrat';
            font-size: 15px;
            color: #fff;
        }


        .height100px {
            height: 80px;
        }

        .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            width: 100%;
            padding-right: var(--bs-gutter-x,.75rem);
            padding-left: var(--bs-gutter-x,.75rem);
            margin-right: auto;
            margin-left: auto;
            padding-bottom: 0px;
        }
 
}


/* landscape ipad 1.5 */
@media (min-width: 1024px) and (max-width: 1600px) {

    .flex_section{
        margin-left: 5%;
        margin-right: 5%;
    }

    .blogitem {
        width: 200px;
        padding-left: 60px;
    }

    .box_list {
        margin-left: 0px;
        margin-top: 20px;
    }
    .box_list li {
        border-radius: 2px;
        padding-left: 5px;
    }
    .slideritems {
        width: 100%;
        margin: 5px;
        /* margin: 40px; */
    }
    .tabletlandscape{
        display: block;
    }
    .mobilelayout{
        display: none;
    }
    .section_columnboxes {
        display: flex !important;
        gap: 50px;
    }

    .whychooseus {
        width: auto;
    }

    .imagetextflex{
        flex-direction: row;
    }

    .trending_title_white {
        font-family: 'Montserrat';
        font-size: 30px;
        color: #ffff;
        text-align: left;
    }

    .trending_desc_small_white {
        font-family: 'Montserrat';
        color: #ffff;
        text-align: left;
    }

    .home_hero {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 40px 40px;
        background-position-x: 550px;
        background-position-y: 260px;
    }


    .blog_header {
        height: 350px !important;
        position: relative;
        background-image: url(https://wiseconnect.bewise.in/static/media/blogclassroom.52d1d37….png),linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .bloginfo {
        position: unset;
        padding: 20px;
        padding-top: 190px;
    }

    .blog_middle {
        padding-left: 50px;
        padding-right: 50px;
    }

    .tag {
        font-size: 10px;
    }


    .iconnumber {
        font-family: 'Montserrat';
        color: #393999;
        font-size: 25px;
        padding: 12px;
        border: 1px solid #393999;
        display: inline-block;
        border-radius: 5px;
        padding-top: 1px;
        padding-bottom: 0px;
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .title_small {
        padding: 20px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 13px;
    }

    .plainlink {
        padding: 8px;
        border: 1px solid #471BF5;
        border-radius: 10px;
        font-size: 13px;
        color: #471BF5;
    }

    .courserow {
        display: flex;
        margin-bottom: 30px;
        cursor: pointer;
        align-items: center;
        gap: 10px;
    }

    .extblog_title {
        font-family: 'Montserrat';
        font-size: 13px;
        color: #000;
        font-weight: 600;
        line-height: 25px;
        padding-top: 5px;
        text-transform: capitalize;
    }

    .plainlink_small {
        padding: 7px;
        border: 1px solid #471BF5;
        border-radius: 10px;
        font-size: 13px;
        color: #471BF5;
    }

    .tiles {
        display: flex;
        gap: 40px;
        width: 100%;
        flex-direction: row;
        align-items: normal;
        margin-bottom: 40px;
    }
    .buildingbg {
        width: 100%;
        height: 500px;
    }

    .watermark {
        position: absolute;
        z-index: 8;
        left: 38%;
        /* margin-top: -7%; */
        /* opacity: 1; */
        top: 120%;
    }

    .borderedbox {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 10px;
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin: 5px;
    }

    .component-26-child {
        height: 70px;
    }

    .profilesearch {
        width: 100%;
        height: 70px;
        border-radius: 10px 0px 0px 10px;
    }
    .vuesaxlinearfilter-square-icon{
        width: 40px;
    }

    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 15px;
    }

    .profilephoto img {
        width: 125px;
        float: left;
        border-radius: 65px;
        margin-right: 25px;
        height: 125px;
    }

    .formgroup {
        display: block;
        clear: both;
        margin-top: 5px;
        margin-bottom: 12px;
    }

    .profilephoto span {
        margin-top: 12px;
        display: block;
        font-size: 13px;
    }

    .profilephoto {
        display: block;
    }

    .profilephoto input{
        margin:auto;
    }    

    .profile_middle{
        display: block;
        margin-top: 40px;
    }


    .courseimg img {
        width: 600px;
        height: auto;
    }

    .courseicon {
        width: 80px;
    }

    .course_h3title {
        font-family: 'Montserrat';
        font-size: 30px;
        color: #fff;
        font-weight: 700;
        padding: 30px;
        padding-bottom: 10px;
        text-align: left;
    }

    .course_desc {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 30px;
        font-family: 'Montserrat';
        font-size: 15px;
        color: #fff;
    }


    .height100px {
        height: 80px;
    }

    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        width: 100%;
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 0px;
    }

    .innerBgColor{
        margin-left: 4%;
        margin-right: 4%;
    }

    .bigtext {
        color: #393999;
        font-size: 18px;
        font-family: 'Montserrat';
        font-weight: 600;
        margin: 0px;
        text-align: left;
        margin-bottom: 10px;
    }


    .hiw_box {
        height: 195px;
        display: inline-block;
    }

    .tablike {
        cursor: pointer;
        font-family: 'Montserrat';
        background-color: #ffffff;
        padding: 5px;
        border-radius: 7px;
        color: #000;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }
    .option-section {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .filtertitle {
        width: 100%;
        text-align: start !important;
    }
    .semiboldtexted {
        font-weight: 600;
        color: #000;
        text-align: center;
    }
    .savefilterbtn{
        text-align: right;
    }
    .savefiltertxt {
        padding-left: 5px;
        font-family: 'Montserrat';
        text-transform: revert;
        font-size: 10px;
    }

    .vuesaxlinearfilter-square-icon {
        width: 30px;
        height: 30px;
    }

    #profile_middle{
        padding-top:20px;
    }

    .searchbox {
        /* padding: 10px; */
        display: inline-flex;
        padding-top: 5px;
    }
    .job-card-parent {
        margin: 10px;
        display: block !important;
    }

    .wc-column {
        height: auto !important;
        /* width: 100%; */
    }
    
    .c30 {
        width: 25%;
    }

    .c20 {
        width: 20%;
    }

    .wc-row {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }
    .c50 {
        width: 50%;
    }

    .job-card-parent{
        padding:0px;
        margin:0px;
    }
    .searchbox{
        width: 100%;
        padding:0px;
        margin:0px;
    }

    .imagetextflex10 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-left: 7%;
        margin-right: 7%;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .whychooseus .imgleft{
       width: 50%; 
    }

    .whychooseus .txtright {
        width: 420px;
        padding: 30px;
    }
}



.slick-dots li button:before {
    font-size: 15px !important;
}

/* (320x480) Smartphone, Portrait */

@media (min-width: 320px) and (max-width: 480px) {
    
    .wc_footer_subscribe_form{
        display: flex;
        flex-direction: column;
        gap:10px;
    }
    .signinsignupspace{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .postwaitingmessage{
        width: 100%;
        padding: 20px;
    }

    #overlayloginrequire {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 81px;
        background-color: rgba(250, 247, 247, 0.92);
        z-index: 2;
        cursor: pointer;
        filter:unset;
      }

    .toptext_para{
        margin: 10px;
        padding: 20px;
        background-color: unset !important;
        border-radius: 10px;
        font-size: 18px;
        font-family: 'Montserrat';
        font-weight: normal;
        text-align: left;
    }

    .toptext{
        background-position: initial;
        background-size: cover;
        background-repeat: no-repeat;

    }
    .toptext_para {
        color: #ffffff;
        font-weight: 700 !important;
    }

    .webad {
         padding: 0px;
    }


    /* insert styles here */
    .r_avatar img{width: 40px;}

    .blog_main_header {
        background-color: #333333;
        height: auto;
    }

    .blog_main_title {
        /* text-align: center; */
        text-align: left !important;
        font-size: 30px !important;
        margin: 10px !important;
    }

    .blog_by {
        padding-top: 30px;
        color: #ffffff;
        font-family: 'Montserrat';
        font-size: 13px;
        margin: 10px;
    }

    .entryitem {
        display: flex;
        flex-direction: row;
        gap:20px;
    }

    .entryrowgroup {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .entryimg {
        /* float: left; */
        width: 20%;
    }
    .entrytexts {
        /* float: left; */
        width: 100%;
    }

    .editpen {
        float: none;
        padding-right: 20px;
        width: 20%;
    }

    .job_status.applied {
        color: #1A0A5B;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 12px;
    }
    .job_status.green {
        color: green;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 12px;
    }
    .footer_onprofile{
        background-color: white;
        overflow-x: scroll;
        width: 100%;
        padding: 10px;
    }

    .onprofile_menu{
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

    .onprofile_menu li{
        white-space: nowrap;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        margin: 5px;
        border: 1px solid;
        width: auto;
    }

    .cms_section {
        width: 100%;
    }
    
    #overlayimg {
        position: absolute;
        top: 75%;
        left: 50%;
        font-size: 50px;
        color: white;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
    }

    .heading {
        font-size: 30px;
        text-transform: uppercase;
        padding: 10px;
        background: #fff;
        color: #393999;
        transform: skewY(-10deg);
        font-weight: 600;
        line-height: 38px;
        font-family: 'Montserrat';
    }

    #text{
        font-size: 22px;
        font-weight: 600;
    }
    .home_hero{
        margin-left: auto;
        margin-right: auto;  
    }
 
    .toptext {
        margin-left: auto;
        margin-right: auto;
        height: auto !important;
        padding: 20px !important;
    }
 
    .section_columnboxes{
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .footer_common{
        background-color: #FFF0FF;
        padding:20px;
        padding-bottom: 100px;
    }

    .wd100per{
        width: 100%;
        display: block;
    }

    .saveblock .fa{
        padding-right: 5px;
    }
    .job-card {
        position: relative;
        border-radius: 15.81px;
        background-color: var(--color-white);
        overflow: hidden;
        flex-shrink: 0;
        padding: 20px;
        width: 100%;
        margin-bottom: 15px;
        padding-bottom: 5px;
    }

    .topflexboxitem{
        width: auto;
        padding: 10px;
        background-color: #fff;
        margin: 5px;
        border-radius: 10px;
        box-shadow: 0px 4px 15px 0px;
        display: flex;
    }

    .topflexbox{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 15px;
    }

    .jobdesc {
        color: #46474c;
        font-family: 'Montserrat';
        font-size: 13px;
        padding-top: 5px;
    }

    .army-public-school6 {
        font-family: 'Montserrat';
        font-size: 19px;
        color: #000;
        line-height: 32px;
    }

    .blog_main_title {
        text-align: center;
        font-size: 35px;
        margin-top: 10px;
        font-family: 'Montserrat';
        font-weight: 600;
    }
    .inner_header_blog {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .blog_main_content {
        width: 100%; 
        margin-left: auto;
        margin-right: auto;
        text-align: justify;
    }

    .blog_main_content img {
        height: auto;
        width: 100%;
    }

    .blog_main_footer {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .blog_main_footer {
        padding: 10px;
        margin: auto !important;
    }

    .textareainputcssblue {
        border: 1px solid #471BF5;
        border-radius: 10px;
        width: 180px;
    }

    .textareacss {
        background-color: #F4F4F4 !important;
        border-radius: 10px;
        width: 100%;
    }

    .sendbtn {
        margin-top: 10px;
        display: grid;
    }

    .mainvh {
        height: 100%;
        background-color: #EFEFEF;
        /* display: flex; */
        padding-top: 50px;
        
    }

    .inputsubscribe {
        font-family: 'Montserrat';
        background-color: #2B2E3C;
        color: #FFFFFF;
        line-height: 28px;
        border-radius: 5px;
        width: 100%;
    }

    .btn-subscribe {
        font-family: 'Montserrat';
        font-size: 12px;
        background-color: #471BF5;
        height: auto;
        border-radius: 5px;
        color: #FFFFFF;
        font-weight: 600;
        width: 100%;
    }

    .padding20{
        padding:20px;
        text-align: center;
    }

    .innermainblock {
        height: 100%;
        padding: 10px;
        width: 100%;
    }

    .ratings {
        padding-top: 10px;
        padding-bottom: 50px;
        padding-left: 14px;
        padding-right: 14px;

    }

    .search_icon_m span{
        top: 12px;
        position: relative;
    }

    .footer_menu {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: row;
        padding: 10px;
        padding-top: 20px;
        gap: 10px;
    }

    .search_icon_m img {
        position: absolute;
        bottom: 30px;
        width: 100%;
        border: 1px solid lightgrey;
        border-radius: 50px;
        background-color: lightgray;
        padding: 5px;
    }

    .c_name span {
        width: 100%;
        font-size: 13px;
        font-weight: normal;
        /* padding-left: 141px; */
        display: inline-block;
    }

    .newcourses{
        padding-top: 20px;
    }
    .listtitle{
        font-family: 'Montserrat';
        color: #000;
        font-weight: 600;
        font-size: 16px;
        padding: 20px;
        padding-bottom: 0px;
    }

    .courselist_mobile{
        /*display: inline-flex; gap: 24px;*/
        display:block;
        padding: 20px;
    }
    
    .courserow_mobile {
        width: auto!important;
        padding: 5px;
        margin: 0px;
        margin-right: 0px;
        display: inline-block;
    }

    .courseinfo {
        width: 100%;
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 4px;
    }

    .courseinfo_title {
        font-family: 'Montserrat';
        font-size: 13px;
        color: #000;
        font-weight: 600;
        line-height: 18px;
    }

    .courseinfo_ins {
        font-family: 'Montserrat';
        font-size: 12px;
        color: #000;
        line-height: 14px;
    }

    .inner_block_mobile{
        margin-top: 5px;
        margin-bottom: 100px;
    }   

    .applybutton {
        width: 100%;
        display: flex;
        text-align: end;
    }

    .share-button {
        cursor: pointer;
        width: 100%;
        text-align: start;
    }

    .profile_topsection{
        display: block;
        align-items: center;
        padding-top: 30px;
    }

    .profile_buttons {
        width: 100%;
        padding-right: 20px;
        text-align: right;
        padding-bottom: 20px;
    }

    .experience_section {
        background-color: #ffffff;
        border-radius: 15px;
        padding: 20px !important;
        margin: 0px !important;
        margin-bottom: 20px !important;
    }

    .spacer{
        
        height: 100px;
    }

    /* edit profile */
    .profilephoto img {
        width: 150px;
        float: left;
        border-radius: 75px;
        margin-bottom: 20px;
    }

    .profilephoto {
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }

    .profilephoto input{
        margin-left: 50%;
        margin-right: 20%;
    }

    .phonenfield {
        width: 72%;
        border-radius: 0 4px 4px 0px;
    }
    
    .home_hero{
        display: inline-block !important;
    }

    .hero_head {
        font-family: 'Montserrat';
        font-size: 24px;
        padding: 12px;
        color: #393999;
        font-weight: 700;
    }

    .hero_desc {
        padding-left: 10px;
        font-family: 'Montserrat';
        font-size: 16px;
        color: #000000;
    
        padding-right: 10px;
    }

    .hero_search {
        padding: 15px;
        background-color: #fff;
        margin-left: 0px;
        border-radius: 10px;
        margin-top: 20px;
    }
    .btn-main-full {
        font-family: 'Montserrat';
        background-color: #471BF5;
        padding: 10px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        width: 100%;
        line-height: 15px;
    }

    .section_head_desc {
        color: #393999;
        font-size: 25px;
        font-family: 'Montserrat';
        font-weight: 600;
        margin: 20px;
    }
    
    .hiw_box {
        background-color: #E6E0FF;
        padding: 30px;
        border-radius: 10px;
        width: 300px;
        margin-bottom: 20px;
    }
    
    .section_columnboxes {
        display: block;
        margin-top: 45px;
    }

    .btn-main-big {
        display: inline;
        margin: 20px;
        font-family: 'Montserrat';
        background-color: #471BF5;
        padding: 10px;
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .sectionbutton {
        padding: 0px;
        display: inline-flex !important;
    }

    .white{
        background-color: #ffffff;
    }

    .component-26-child {
        height: 41px;
    }
    
    .filtericon{
        height: 40px;
    }

    .profilesearch {
        width: 100%;
        height: 41px;
        border-radius: 10px 0px 0px 10px;
    }

    .tablike {
        cursor: pointer;
        font-family: 'Montserrat';
        background-color: #ffffff;
        
        border-radius: 7px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }

    .savefilter {
        display: flex;
        padding: 10px;
        margin: 10px;
        align-items: center;
    }

    .job-card-item{
        width: 80px;
    }

    .jobtype {
        line-height: 16px;
    }

    .expandbtn img {
        float: right;
        /* vertical-align: middle; */
        width: 18px;
        display: flex;
        margin-left: 5px;
    }
    .expandbtn {
        color: #1a0a5b;
        font-family: 'Montserrat';
        font-size: 12px;
        padding: 10px;
        cursor: pointer;
        text-align: right;
        line-height: 18px;
        padding-right: 5px;
    }
    .activetab {
        cursor: pointer;
        font-family: 'Montserrat';
        background-color: #471BF5;
        padding: 15px;
        border-radius: 7px;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }

    .notetoothers{
        font-size: 12px;
    }

    .othercourselist_mobile{
        padding: 20px;
        height: 250px;
        overflow-y: scroll;
    }

    /*why choose us*/
    .whychooseus{
        width: 100%;
    }

    .wcdesc_normal{
        width: 100%;
    }

    .imagetextflex {
        display: flex;
        margin-top: 100px;
        margin-bottom: 100px;
        gap: 40px;
        align-items: center;
    }

    .txtright{
        width: 100%;
        text-align: center;
    }

    .advisorysection{
        width: 100%;
    }
    
    .buildingbg{
        width: 100%;
        height: 200px;
    }

    .tiles {
        display: flex;
        gap: 40px;
        width: 100%;
        flex-direction: column !important;
        align-items: normal;
        margin-bottom: 40px;
    }

    .home_hero{
        padding: 30px !important;
        font-size: 30px !important;
        text-align: center !important;
    }

    .hero_desc{
        text-align: justify;
    }

    .hiw_desc {
        font-family: 'Montserrat';
        color: #000000;
        font-size: 13px;
        /* font-weight: 600; */
        padding: 5px;
        text-align: justify;
    }

    .articleswc{
        background-color: #E6E0FF;
        padding: 10px;
    }

    .whychooseus_m{
        background-color: #ffffff;
        padding:10px;
        padding-top: 60px;
    }

    .wctitle_large {
        color: #393999;
        font-family: 'Montserrat';
        font-size: 21px;
        font-weight: 700;
        padding-bottom: 25px;
        text-align: center;
    }

    .whychoose_content{
        padding: 15px;
        text-align: center;
    }

    .wcdesc_normal{
    padding: 15px;
    font-size: 16px;
    text-align: justify;
    }

    .hiw_box {
        background-color: #E6E0FF;
        padding: 30px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 20px;
    }

    .home_hero {
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 40px 40px;
        background-position-x: 375px;
        background-position-y: 120px;
    }

    .section_howitworks{
        background-repeat: no-repeat;
        background-size: 140px; 
        background-position: left;
        padding-bottom: 80px;
    }

    .trending_title_white {
        font-family: 'Montserrat';
        font-size: 18px;
         color: #000; 
        text-align: left;
        padding-left: 13px;
    }

    .fb_img{
        padding: 10px;
    }
    .fb_img img{
        border-radius: 10px;
    }

    .trending_desc_small_white {
        font-family: 'Montserrat';
        color: #000; 
        text-align: left;
        padding-left: 16px;
        padding-top: 10px;
    }


    /*
    .trendinglist {
        padding: 20px;
        background-color: white;
        margin: 10px;
        border-radius: 10px;
        margin-top: 40px;
    }
    */
    .trendinglist {
              
        margin: 10px;
        border-radius: 10px;
        margin-top: 40px;
    }
    .fleximgblog{
        width: 25%;
    }
    .flexcontblog{
        width: 75%;
    }
    .trending_title {
        padding:0px; 
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 13px !important;
        padding-bottom: 10px;
    }
    .trending_title_white span {
        color: #393999;
        padding: 5px;
        margin-left: 5px;
        font-size: 12px;
        border-radius: 5px;
        font-style: italic;
    }

    .ul_othercourses li{
        width: auto;
    }

    .closeit{
    border-radius: 50px;
    background-color: #fff;
    width: 30px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    line-height: 30px;
    padding-left: 10px;
    box-shadow: 0px 1px 1px 0px #000000;
    font-size: 15px;
    right: 15px;
    position: absolute;
    }
    .modal-title{
        width: 100%;
    }
    .coursename_d{
        font-size: 23px;
    }

    .othercourselist_mobile {
        padding: 20px;
        height: 290px;
        overflow-y: scroll;
    }

    .watermark {
        position: fixed;
        z-index: 8;
        margin-top: -7%;
        opacity: 1;
        top: 50%;
    }

    .textright_title {
        color: #393999;
        font-family: 'Montserrat';
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
        height: 70px;
    }

     /*
    .whychooseus_m .slick-dots{
        top:-25px;
        bottom:unset;
    }
    */

    .wcdesc_normal {
        padding: 15px;
        text-align: justify;
        margin-bottom: 30px;
    }

    .section_columnboxes {
        display: block;
        margin-top: 45px;
    }

    .trending_title {
        padding-top: 11px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 10px;
        padding-left: 10px;
        background-color: #ffff;
    }
    .fleximgblog{
        width: 100%;
    }
 
    .flexcontblog{
        width: 100%;
    }

    .trendingitemrowview{
        display: block;
        box-shadow: 0px 6px 6px -5px #000;
        padding: 0px;
        margin: 10px;
    }

    .blogitemrow{
        position: relative;
        min-height: 220px;
    }

    .flexcontblog{
        height: 50px;
        background-color: #fff;
    }
    .articleswc {
        background-color: #E6E0FF;
        padding: 10px;
        padding-bottom: 70px;
    }

    .leveldropdowns{
        padding-left: 30px;
        display: flex;
        padding-right: 30px;
    }
    .positionbox {
        padding: 20px;
        padding-top: 10px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        display: inline;
    }
    .profile_buttons {
        width: 100%;
        padding: 10px;
        display: grid;
    }

    .watermark {
        position: fixed;
        z-index: 8;
        margin-top: -7%;
        opacity: 1;
        top: 50%;
        left: 5%;
    }

 
    .bloginfo {
        position: unset;
        padding: 20px;
        padding-top: 70px;
    }

    .blog_header {
        height: 145px !important;
        position: relative;
        background-image:url("../../src/assets/images/blogwallpaper2.jpg"),linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center !important;
    }


    .bloginfo h2 {
        font-size: 20px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #fff;
        line-height: 30px;
    }

    .slidings{
        width: auto;
        overflow-y: scroll;
        display: flex;
        scroll-behavior: smooth;
        scrollbar-color: red yellow;
    }

    .slidings {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    }
    .slidings::-webkit-scrollbar-thumb {
        background-color: black;
    }


    .mainblock{
        background-color: #ffff;
        padding: 10px;
    }

    .category_tags {
        margin: 10px;
        padding: 10px;
    }
    .categorizedblogs .tag {
        width: auto;
        max-width: 200px;
        min-width: 200px;
    }

    .slider{
        padding:10px;
        margin:10px;
    }

    .categorizedblogs .bloglink{
        display: block;
        text-align: center;
        margin-left:0px;
    }
    .blogimg img{
        width: 100%;
    }

    .slick-list{
        padding-bottom: 30px;
    }

    .whitebg{
        background-color: #ffffff;
    }

    .trendingnow {
        width: 100%;
        height: auto;
        padding: 5px;
    }
    .trendinglist {
        margin: 10px;
        border-radius: 10px;
        /* margin-top: 40px; */
        padding: 10px;
    }

    .bloginfo_img {
        width: 45%;
    }
    .bloginfo_img img{
        width: 100%;
    }

    .bloginfo_text{
        width: 65%;
    }
    .flexitem {
        width: 100%;
        display: flex;
        gap:0px;
    }   

    .trendingitemrow {
        margin-bottom: 20px;
    }

    .trending_extra a {
        /* padding-left: 20px; */
        font-size: 12px;
        color: #471BF5;
    }
    .featuredblog{
        border-radius: 0;
        height: auto;
    }
    .borderedradius {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
    .curated_list {
        width: 100%;
        padding: 10px;
        margin: 10px;
    }

    footer {
        bottom: 0px;
        position: fixed;
        width: 100%;
        box-shadow: 0px 37px 15px 30px #000;
    }

    .filterbtns{
        display: flex;
    }

    .fi {
        width: 100%;
        padding: 10px;
        background-color: #fff;
        margin: 10px;
        border-radius: 10px;
        box-shadow: 0px 4px 15px 0px;
    }

    .filteropen{
        font-size: 13px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #471BF5;
    }

    .filterblock{
        padding-bottom: 10px;
    }

    .savefilter span{
        width: 100%;
        text-align: center;
    }

    .text-right{
        text-align: right;
    }
    
    .text-left{
        text-align: left;
    }

    .bookmarking {
        text-align: end;
        width: 17%;
    }

    .applybutton button {
        /* width: 100%; */
        line-height: 12px;
    }

    .bookmarking img {
        width: 40px;
        padding-top: 5px;
        height: 40px;
        display: inline-block;
    }

    .savefiltertxt {
        padding-left: 5px;
        font-family: 'Montserrat';
        text-transform: revert;
        font-size: 14px;
    }

    .frame-group {
        flex-direction: column;
        padding: 25px 25px !important;
        gap: 37px !important;
    }

    .blogitem{
        width: 100% !important;
        padding-bottom: 20px;
    }
    .categorylist {
        height: 95px;
        padding: 10px;
    }

    .disflex{
        display:flex;
    }
    .ScrollbarsCustom-ThumbX{
        cursor: pointer;
        border-radius: 4px;
        background: #471BF5 !important;
        height: 100%;
        width: 147.84px;
        transform: translateX(0px);
    }

    .mainvh{
        padding-top: 60px;
    }

    .toptext{
        background: #F4F8FB;
        padding: 10px;
        /* margin: 13px; */
        display: flex;
    }

    .toptext_para{
        font-size: 18px;
        font-weight: normal;
        text-align:left;
        width:100% !important;
        margin: 0px !important;
        padding: 20px !important;
    }
    .home_hero{
        position: relative;
    }
    .glitter img {
        width: 50px;
        position: absolute;
        animation: zoom-in-zoom-out 1s ease infinite;
        top: 0px;
    }

    .spacer70 {
        height: 70px !important;
    }

    .bloginfo_img img{
        width: 125px;
        height: 70px;
    }

    .pad20{
         
        padding: 10px;
        min-height: 235px;
    }

    .wc_div {
        display: block;
        padding: 10px;
        text-align: left;
    }

    .wcdesc_normal {
        padding: 15px;
        text-align: left;
        margin-bottom: 30px;
    }

    .hiw_box {
        height: 230px;
        display: inline-block;
    }

    .already-have-an-account{
        font-size: 14px;
    }

    .sign-in1{
        font-size: 14px;
    }

    .fullwidth{
        width: 100%;
    }
    .by-clicking-create-container{
        font-size: 14px;
    }
    .by-clicking-create-container span{
        font-size: 14px;
    }

    .trending_title{
        padding-top: 0px;
        background-color: unset;
    }
    .trending_extra a {
        padding: 8px;
        font-size: 10px;
    }

    .trending_extra {
        margin-top: 5px;
        margin-left: 10px;
    }

    .blogicon {
        width: 55%;
    }



    .externalbloginfo {
        display: flex;
        margin-bottom: 30px;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .othercourseslider{
        background-color: #E6E0FF;
        padding:10px;
    }


    .tablike{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .fl {
        float: left;
        display: block;
        width: 100%;
    }

    .semibordered{
        border-radius: 0px;
    }
    .course_h3title {
        font-family: 'Montserrat';
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        padding: 15px;
        padding-bottom: 10px;
        text-align: left;
    }

    .course_desc {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        font-family: 'Montserrat';
        font-size: 18px;
        color: #fff;
    }
    .blogicon img{
        border-radius: 10px;
    }

    .blogdetail {
        margin-bottom: 2px;
        height: 50px;
    }

    .curated_list h3 {
        padding: 20px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 18px;
        color: #471BF5;
        padding-left: 3px;
    }
    .blogimg{
        padding-left: 0px;
    }

    .bgbanner{
        padding: 10px;
    }

    .wrapper {
        max-width: 500px;
        width: 100%;
        height: auto;
    }


    .team {
        width: 300px !important;
        display: inline-block !important;
        margin-bottom: 60px !important;  
        margin-left: 0px !important;
    }

    .collageimg img{
        width: 100% !important;
    }

    .bloglisting {
        margin: 10px;
        border-radius: 10px;
        /* margin-top: 40px; */
        padding: 10px;
    }

    .extblog_title {
        font-family: 'Montserrat';
        font-size: 16px;
        color: #000;
        font-weight: 600;
        line-height: 25px;
        padding-top: 0px;
        text-transform: capitalize;
    }

    .courserow {
        display: flex;
        margin-bottom: 30px;
        gap: 10px;
        justify-content: center;
    }


    #overlayadvisory {
        position: fixed;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 2;
        cursor: pointer;
        margin-bottom: 80px;
    }

    .textareainputcssblue {
        border: 1px solid #471BF5;
        border-radius: 10px;
        width: 100%;
    }
    
}

.tabletlandscape .optioneachrow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: -21px;
}

.tabletlandscape .h3title{
    padding-left: 0px;
}

.bloglink {
    display: flex;
    margin-left: 12px;
}

/* potraits */
@media only screen   
and (min-width: 786px)   
and (max-width: 1100px)  
{ 

.section_columnboxes{
    flex-direction: column !important;
}

.imgcentered img{
    width: 100%; padding: 10px;
}

.hero_head{
    color: #393999 !important;
}

.home_hero{
    background-position: 200px 100px;
}

.whychooseus_tablet_potrait{
    display: block;
    padding: 20px;
}

.whychooseus_m{
    display: none;
}

.wctitle_large {
    color: #393999;
    font-family: Montserrat;
    font-size: 21px;
}
.wctitle_large {
    font-weight: 600;
    padding-bottom: 25px;
}

.flexequalblk {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
    gap: 60px;
}

}

@media (min-width: 720px) and (max-width: 1280px) {

    .r_avatar img{
        width: 25px;
    }

    .boxed-check-group, .boxed-check-group .boxed-check {
        position: relative;
        margin-bottom: 10px;
        display: grid;
        grid: auto auto / auto auto;
        grid-gap: 10px;
    }

    .main {
        height: 100%;
        background-color: #EDEDED;
        display: flex;
        padding-top: 80px;
    }


    .whychooseus .wcdesc_normal {
        width: 100% !important;
    }

    .flex_section_gap_50{
        gap:0px !important;
    }

    .whychoose_sec1 {
        background-size: 94%;
        background-repeat: no-repeat;
        /* background-position: 5px 180px !important; */
        background-position: 35px 224px !important;
    }

    .unborderedboxnopad {
        border-radius: 6px;
        margin-left: 0px; 
        margin-right: 0px; 
        margin-top: 0px;
        padding: 5px !important;
    }

    .profilesearch {
        width: 100%;
        height: 85px;
        border-radius: 10px 0px 0px 10px;
    }

    .component-26-child {
        height: 70px;
    }

    #profile_middle{
        margin-top: 20px;
    }

    .wc_footer_subscribe_form{
        display: flex;
    }
    .wc-column {
        
        height: auto !important;
    }

    .flex_section_gap_50 {
        padding-left: 40px;
        padding-right: 40px;
        align-items: flex-start;
    }

    .trending_title{
        padding-left: 0px;
    }

    .trending_extra{
        display: flex;
    }

    .grillbg {
        background-size: 165px;
        background-repeat: no-repeat;
        background-position: top right;
    }

    .mobilelayout .tiles{
        flex-direction: column;
    }

    .mobilelayout .buildingbg{
        height: 525px;
    }

    .footer_common {
        background-color: #FFF0FF;
        padding: 20px;
        padding-bottom: 100px;
    }

    .wd100per {
        width: 100%;
        display: block;
    }

    .padding20 {
        padding: 20px;
        text-align: center;
    }

    .wc_logo{
        text-align: left;
    }
    .wc_footer_subscribe{
        text-align: left;
    }

    .imgleft {
        width: 100%;

    }
    .collageimg img{
        width: 100% !important;
    }


     .section_blogs{
        background-size: cover;
     }

     #overlayloginrequire{
        bottom: 0px;
     }
}


