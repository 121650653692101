* {
  box-sizing: border-box;
  line-height: normal;
}
body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
}
.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
}
.common-pointer {
  cursor: pointer;
}
.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}
.dhiwise-navigation h1 {
  color: white;
}
.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}
.dhiwise-navigation ul {
  padding: 0;
}
.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}
.dhiwise-navigation a {
  color: #132cdc;
}
table {
  border-spacing: 0;
  width: 100%;
}
.common-select {
  z-index: 10000;
}
.react-datepicker-popper {
  z-index: 100000 !important;
}
.drawable-icon{
  position: absolute;
  margin: auto;
  z-index: 1000;
}
.input-wrap{
  position: relative;
}
option{
  color: #000;
}
.table-wrap{
  overflow: auto;
}
input:focus{
  outline:none;
}
.ReactModal__Body--open{
  overflow: hidden;
}
  .slider-indicator:first-child {
    margin-left: 0;
}

.slider-indicator:last-child {
margin-right: 0;
}

.rc-drawer-content-wrapper{
  width: 100% !important;
}
.rc-drawer-right .rc-drawer-content-wrapper{
  display: flex;
  justify-content: flex-end;
}
.rc-drawer-left .rc-drawer-content-wrapper{
  display: flex;
  justify-content: flex-start;
}
.ReactModal__Overlay{
  z-index: 1051;
}

ul li a{
  display: block;
}

.mobile-menu div{
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin:4px 0;
}
.mobile-menu {
  display: none;
  cursor: pointer;
}
.header-row{
  align-items:center;
  justify-content: space-between;
  display: flex;
}
@media only screen and (min-width: 200px) and (max-width: 550px)  {
  .mobile-menu {
    display: block;
  }
  .header-row{
    width: 100% !important;
  }
  .display-block{
    display: block;
  }
  .display-none{
    display: none;
  }
  .common-row-list > ul{
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}
.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
.menu-overlay{
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}
.close-line1{
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.close-line2{
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.common-row-list > ul{
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list > ul{
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li{
  width: 100%;
}

.textlist{
  list-style-type: none;
  font-size: 20px;
}

.textlist li{
  line-height: 30px;
  padding-bottom: 9px;
}

.cursorpointer{
  cursor: pointer;
}

.failure{
  color:red
}

.success{
  color:green;
}


.comingsoon{
  text-align: center;
  padding: 20px;
  margin: 24px;
  font-size: 36px;
}

.bluebg{
  background-image: url('/public/images/blue_bg.png');
  background-repeat: no-repeat;
  height: 100%; 
  background-position: center;
  background-size: cover;
}

.wc h2{
  font-family: Montserrat;
  font-size: 44.7px;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(26 10 91 / var(--tw-text-opacity));
}
.exploresection{
  top: 100px;
  position: relative;
}
.bottomsection{
display: flex;
justify-content: space-between;
padding:50px;
}
.topsection{
  display: flex;
  justify-content: space-between;
  padding-left:25px;
  padding-right: 25px;
}
.wc-code{
  overflow-x: hidden;
}
.maininput{
  width: 390px;
  margin-right: 15px;
}

.menutext{
  font-size: 13px;
  line-height: 13px;
  padding-left:15px;
  padding-right: 15px;
}

.heroslider{
  display: flex;
  justify-content: space-between;
  padding:50px;
} 

.textlist{
  /*list-style-type: disclosure-closed;*/
  font-family: 'Montserrat';
  margin-left: 5px;
}

.socialicons{
  margin-top: auto;
  margin-bottom: auto;
}

.menu{
  margin-top: auto;
  margin-bottom: auto;
}
.signupnmessages{
  margin-top: 40px;
}
.blacktext{
  font-weight: bolder;
}

.bluetext{
  font-weight: bolder;
  color:#001BF5;
}

.noborder{
  border:none;
}

.hidden{
  display: none;
}

#cycler img{
  z-index: 1;
}

#cycler .show{
  z-index: 2;
}

.socialicon{
  cursor: pointer;
}

.footersection{
  background-color: #1A0A5B;
  background-image: url('/public/images/footerbgmixed.png');
  background-repeat: no-repeat;
  background-position: bottom;
  padding-top: 75px;
  padding-bottom: 20px;
}

.column50{
  width: 50%;
  padding:10px
}


.column50nopad{
  width: 50%;
}

.column100{
  width: 100%;
  padding:10px
}

.contactustitle{
  color: #F9F9FB;
  font-size: 44.72px;
}
.footerformbox{
  background: #FFFFFF;
  padding: 33px;
  border-radius: 15px;

}

.footerformbox label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #000000;
}
/* Style the input container */
.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px;
}

/* Style the form icons */
.icon {
   
  color: #292D32;
  
  text-align: center;
}

/* Style the input fields */
.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.footerformbox .input-container{
  flex-direction: unset;
}

.footerformbox .input-container input{
  height: 12px;
  font-size: 14px;
}


.footerformbox .input-container input{
  border:none;
}
.input-container:focus {
  border: 2px solid dodgerblue; 
}

.footerformbox .icon{
  font-size: 17px;
}


.wcsubmitbtn{
  background: #471BF5;
}
.newSocialIcons{
  display: flex;
  gap: 10px;
  margin-top: 80px;
}

.contactusmail {
  color: #F9F9FB;
  margin-top: 25px;
  font-size: 21px;
  font-family: 'Montserrat';
}

.contactussubtitle{
  color:#F9F9FB;
  font-size: 21px;
}
.contactusmail .icon{
  color:#F9F9FB;
  padding:0px;
  min-width: auto;
}

.wcsubmitbtn {
  background-color: #471BF5 !important;
  width: 100%;
  padding: 11px;
  font-size: 18px;
  color: #fff;
  border-radius: 9px;
}

.comingsoontext{
  font-weight: 700;
  font-size: 26px !important;
  color: #1A0A5B;
}

.returnmsg{
  text-align: center;
  padding: 10px;
  font-size: 20px;
}

.contactustitle {
  color: #F9F9FB;
  font-size: 34px;
  font-family: 'Montserrat';
  font-weight: 800;
}

.contactussubtitle {
  color: #F9F9FB;
  font-size: 21px;
  font-family: 'Montserrat';
}

.clickbtn{
  float: right;
    height: auto;
    padding: 9px;
}

.takemetobewise{
  cursor: pointer;
  background-color: #ffffff;
  color:#212529;
}

.wcsubmitbtn {
  font-weight: 700;
  font-family: 'Montserrat';
}
.newSocialIcons {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.contactusmail {
  color: #F9F9FB;
  margin-top: 70px;
  font-size: 21px;
}

.tablets .menuham{
  width: 25px;
}

.tablets .takemetobewise{
    margin-top: 24px !important
}

.tablets .footerformbox{
  margin-top: 20px;
}
.tablets .footersection {
  padding:20px;
}
.tablets .benefits{
  padding-left: 35px;
  padding-bottom: 44px;
}

.micro-slider {
  height: 480px;
  margin: 64px 0;
  position: relative;
  width: 100%;
}

.micro-slider.fullwidth {
  height: 480px;
  margin: 64px 0;
}

.micro-slider.fullwidth .slider-item {
  height: 480px;
  line-height: 480px;
  width: 100%
}

.slider-wrapper {
  height: 525px !important;
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 0 50%;
}

.slider-item {
   
  display: none;
  height: 400px;
  line-height: 400px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  opacity: 1 !important;
  width: 400px;
}

.slider-item:not(.active) {
  cursor: pointer;
}

.indicators {
  bottom: -64px;
  left: 50%;
  list-style-type: none;
  margin: 0 0 0 -77px;
  padding: 0;
  position: absolute;
}

.indicators li {
  color: #FFF;
  float: left;
  height: 16px;
  margin-right: 8px;
  text-align: center;
  width: 16px;
}

.indicators li:last-child {
  margin: 0;
}

.indicators a {
  background: #FFF;
  border-radius: 8px;
  border: 1px solid #E6E9EC;
  color: #FFF;
  display: inline-block;
  height: 16px;
  width: 16px;
}

.indicators .active a {
  background: #E6E9EC;
}

.s1 {
  background: #3f51b5;
}

.s2 {
  background: #448aff;
}

.s3 {
  background: #03a9f4;
}

.s4 {
  background: #00bcd4;
}

.s5 {
  background: #009688;
}

.s6 {
  background: #4caf50;
}
.text12{
  font-size: 12px;
}
.tablet_whattoexpect{
    font-size: 38px;
    font-family: 'Montserrat';
    color: #1A0A5B;
    font-weight: 800;
}

.tablets .active .card {
  box-shadow: 2px 4px 25px 5px #000;
}

.signupboxelements{
    padding: 6px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.newSocialIcons img{
  cursor:pointer;
}

.flex_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.heroleft_content_section{
  width: 50%;
}

.heroright_content_section{
  width: 50%;
}

.home_hero {
  background-color: #F4F8FB;
  display: flex;
  padding: 50px !important;
}