:root { 
--gray_600:#6e6e6e; 
--blue_900:#0118ca; 
--gray_901:#1f1f1f; 
--indigo_900_a2:#1a0a5ba2; 
--gray_900:#181818; 
--gray_200:#ededed; 
--black_900:#000000; 
--red_A400:#f40022; 
--deep_purple_A400:#471bf5; 
--indigo_900:#1a0a5b; 
--gray_800_a2:#444444a2; 
--white_A700:#ffffff; 
--indigo_500:#4553d6; 
--black_900_26:#00000026; 
}