
@media (min-width: 1281px){

    .logo{
        width: 200px;
    }

    .menu{
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 63px;
    }

    .menutext {
        font-size: 20px;
        line-height: 20px;
        /*padding-left: 15px;*/
        padding-right: 15px;
        padding-left: 105px;
    }

    .wc-code h1{
        font-size: 34px;
        padding-bottom: 14px;
    }

    .comingsoontext {
        font-weight: 700;
        font-size: 19px !important;
        color: #1A0A5B;
    }
    .heroslider span{
        font-size: 18px;
    }
    .textsection{
        width: 46%;    
    }

    .blacktext{
        font-size: 20px;
    }

    .promisetext{
        font-size: 20px !important;
    }

    .signupbox{
        margin-top: 100px !important;
    }
    .signupboxelements input{
        font-size: 17px !important;
         
    }

    .signupboxelements button{
        font-size: 20px !important;
        width: 30%;
        padding: 9px;
    }

    .clickbtn{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .arrivingsoon{
        font-size: 30px !important;
        top: 170px !important;
        font-weight: bolder;
        right: 4% !important;
    }
    
    .whattoexpect .benefits{
        margin-top: 25px;
    }
    .whattoexpect span{
        font-size: 19px !important;
    }

    .textlist {
        margin-left: 9px;
    }
    .benefitslist{
        padding:30px !important
    }
    .textlist{
        font-size: 18px !important;
    }

    .socialicons .socialicon{
        padding:20px !important;
    }

    .bottomsection{
        padding-left: 175px;
        padding-right: 175px;
    }
 
    .takemetobewise{
        margin-top: 220px;
    }    

    .signupboxelements{
        border:1px solid #000 !important;
        border-radius: 10px !important;
    }
}

@media (min-width: 1025px) and (max-width: 1280px){

    .logo{
        width: 150px;
    }
    .menutext {
        font-size: 13px;
        line-height: 13px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .gap{
        gap:120px !important;
    }
    .signupboxelements{
        width: 90% !important;
    }

    .takemetobewise{
        margin-top: 320px;
    }  
    
    .menuham{
        width: 25px;
    }
}

/* potrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {


    .potraits{
        display: block;
    }
    .landscape{
        display: none !important;
    }

    .potraitflex{
        display:flex;
    }

  }

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .potraits{
        display: none;
    }
    .landscape{
        display: block;
    }

    .potraitflex{
        display:inline-flex;
    }

    .tablets .benefits {
        padding-left: 5px;
        padding-bottom: 5px;
    }

    .textsection {
        width: 100%;
        padding-right: 30px;
    }

    .contactustitle {
        color: #F9F9FB;
        font-size: 30px;
        font-family: 'Montserrat';
        font-weight: 800;
    }

    .contactussubtitle {
        color: #F9F9FB;
        font-size: 16px;
        font-family: 'Montserrat';
    }

    .contactusmail {
        color: #F9F9FB;
        margin-top: 120px;
        font-size: 17px;
    }

    .tablets .takemetobewise {
        margin-top: 197px !important;
    }
    .takemetobewise img{
        width: 100px;
    }

    .menutext {
        font-size: 13px;
        line-height: 13px;
        padding-left: 35px;
        padding-right: 35px;
    }
    .comingsoontext {
        font-weight: 700;
        font-size: 15px !important;
        color: #1A0A5B;
    }

    .bottomsection {
        display: flex;
        justify-content: space-between;
        padding: 30px;
    }

    .whattoexpect{
        padding-left:5px !important;
        padding-right: 5px !important;
    }

    .signupboxelements input{
        width: 100%;
        font-size: 15px !important;
        padding-right: 10px;
    }
    
}



@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {


     .smartphones{
        display: none;
     }
     .tablets{
        display: block;
     }

     .menuham{
        width: 25px;
    }
  
    .menu {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 15px;
    }

    .arrivingsoon {
        font-size: 12px !important;
        top: 117px !important;
        right: 2% !important;
        font-weight: bolder;
    }
    .heroc{
        padding-left: 40px;
        padding-right: 40px;
    }
    .wc-code h1 {
        font-size: 28.06px;
        padding-bottom: 10px;
    }
    .textsection{
        width: 100%;
    }
    .heroslider{
        padding:30px
    }
    .heroslider span {
        font-size: 13.61px;
    }
    .signupbox{
        margin-top: 20px !important;
        
    }
    .blacktext{
        font-size: 15.66px;
    }

    .textlist {
        font-size: 13px !important;
    }

    .imagesection{
        margin-top: 0px;
 
    }

    .signupboxelements {
       width: 100% !important; 
    }
    
    .signupboxelements input, .signupboxelements button{
        width: 100%;
        font-size: 15px !important;
    }
    .promisetext{
        font-size: 15px !important;
    }
    .benefits{
        padding:20px;
    }

    .benefitslist{
        margin-bottom: 20px;
    }

    .whattoexpectheading{
        font-size: 13.96px !important;
    }

    .bottomsection {
        display: flex;
        justify-content: space-between;
        padding: 30px;
    }
    .socialicons .socialicon {
        padding: 2px !important;
    }
    .takemetotext{
        font-size: 8.74px !important;
    }
    .arrowone{
        width: 25px;
        padding-left:5px;
    }

    .footerformbox {
        background: #FFFFFF;
        padding: 30px;
        border-radius: 15px;
        margin-top: 35px;
    }

    .whattoexpect{
        padding-left:5px !important;
        padding-right: 5px !important;
    }
}


/* Portrait other tablets*/
@media 
  (max-device-width: 943px) 
  and (orientation: portrait) { 

    .potraits{
        display: block;
    }
    .landscape{
        display: none;
    }

    .potraitflex{
        display: flex;
    }
}

/* Landscape  other tablets*/
@media 
  (max-device-width: 1569px) 
  and (orientation: landscape) { 

    .potraits{
        display: none;
    }
    .landscape{
        display: block;
    }
    .bottomsection{
        display: flex !important;
    }

    .tablets .benefits {
        padding-left: 5px;
        padding-bottom: 5px;
    }

    .textsection {
        width: 100%;
        padding-right: 30px;
    }

    .contactustitle {
        color: #F9F9FB;
        font-size: 30px;
        font-family: 'Montserrat';
        font-weight: 800;
    }

    .contactussubtitle {
        color: #F9F9FB;
        font-size: 16px;
        font-family: 'Montserrat';
    }

    .contactusmail {
        color: #F9F9FB;
        margin-top: 120px;
        font-size: 17px;
    }

    .tablets .takemetobewise {
        margin-top: 197px !important;
    }
    .takemetobewise img{
        width: 100px;
    }

    .menutext {
        font-size: 13px;
        line-height: 13px;
        padding-left: 35px;
        padding-right: 35px;
    }
    .comingsoontext {
        font-weight: 700;
        font-size: 15px !important;
        color: #1A0A5B;
    }
    .whattoexpect{
        padding-left: 18px !important;
        padding-right: 30px !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px){

    .logo{
        width: 150px;
    }

    .benefits {
        padding: 26px;
    }
    .carousel {
        position: relative;
        width: 400px;
        height: 420px;
        perspective: 500px;
        margin-left: 160px;
        transform-style: preserve-3d;
   }

    .card-container {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotateY(calc(var(--offset) * 50deg)) scaleY(calc(1 + var(--abs-offset) * -0.4)) translateZ(calc(var(--abs-offset) * -30rem)) translateX(calc(var(--direction) * -5rem));
        filter: blur(calc(var(--abs-offset) * 1rem));
        transition: all 0.3s ease-out;
   }
    .card {
        width: 100%;
        height: 100%;
        padding: 2rem;
        border-radius: 1rem;
        color: #9ca3af;
        text-align: justify;
        transition: all 0.3s ease-out;
   }
    .card h2 {
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        margin: 0 0 0.7em;
        color: #1f2937;
   }
    .card p, .card h2 {
        transition: all 0.3s ease-out;
        opacity: var(--active);
   }
    .nav {
        color: white;
        font-size: 5rem;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        z-index: 2;
        cursor: pointer;
        user-select: none;
        background: unset;
        border: unset;
   }
    .nav.left {
        transform: translateX(-100%) translatey(-50%);
   }
    .nav.right {
        right: 0;
        transform: translateX(100%) translatey(-50%);
   }

    
    .smartphones{
        display: none;
    }
    .tablets{
        display: block;
    }
    .menuham{
        width: 25px;
    }
  
    .menu {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 15px;
    }

    .arrivingsoon {
        font-size: 12px !important;
        top: 117px !important;
        right: 2% !important;
        font-weight: bolder;
    }
    .heroc{
        padding-left: 40px;
        padding-right: 40px;
    }
    .wc-code h1 {
        font-size: 28.06px;
        padding-bottom: 10px;
    }
    .textsection{
        width: 100%;
    }
    .heroslider{
        padding:30px
    }
    .heroslider span {
        font-size: 13.61px;
    }
    .signupbox{
        margin-top: 20px !important;
        
    }
    .blacktext{
        font-size: 15.66px;
    }

    .textlist {
        font-size: 12px !important;
        
    }

    .imagesection{
        margin-top: 0px;
 
    }

    .signupboxelements {
       width: 100% !important; 
    }
    
    .signupboxelements input, .signupboxelements button{
        width: 100%;
        font-size: 13px !important;
    }
    .promisetext{
        font-size: 14px !important;
    }
    .benefits{
        padding:20px;
    }

    .benefitslist{
        margin-bottom: 20px;
    }

    .whattoexpectheading{
        font-size: 13.96px !important;
    }

    .bottomsection {
        display: flex;
        justify-content: space-between;
        padding: 30px;
    }
    .socialicons .socialicon {
        padding: 2px !important;
    }
    .takemetotext{
        font-size: 8.74px !important;
    }
    .arrowone{
        width: 25px;
        padding-left:5px;
    }

    .footerformbox {
        background: #FFFFFF;
        padding: 30px;
        border-radius: 15px;
        margin-top: 35px;
    }
}


 /* Extra Small Devices, Phones */ 
 @media (max-width: 480px) {

    .logo{
        width: 150px;
    }    

    .tablets{
        display: none;
    }
    .smartphones{
        display: block;
    }
    .menuham{
        width: 25px;
    }
  
    .menu {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 15px;
    }

    .arrivingsoon {
        font-size: 12px !important;
        top: 117px !important;
        right: 2% !important;
        font-weight: bolder;
    }
    .heroc{
        padding-left: 40px;
        padding-right: 40px;
    }
    .wc-code h1 {
        font-size: 20.35px;
        padding-bottom: 10px;
    }
    .textsection{
        width: 100%;
    }
    .heroslider{
        padding:30px
    }
    .heroslider span {
        font-size: 13.61px;
    }
    .signupbox{
        margin-top: 20px !important;
        
    }
    .blacktext{
        font-size: 15.66px;
    }

    .textlist {
        font-size: 13px !important;
    }

    .imagesection{
        margin-top: 0px;
        margin-bottom: 30px;
    }

    .signupboxelements {
       width: 100% !important; 
    }
    
    .signupboxelements input, .signupboxelements button{
        width: 100%;
        font-size: 15px !important;
    }
    .promisetext{
        font-size: 15px !important;
    }
    .benefits{
        padding:20px;
    }

    .benefitslist{
        margin-bottom: 20px;
    }

    .whattoexpectheading{
        font-size: 13.96px !important;
    }

    .bottomsection {
        display: flex;
        justify-content: space-between;
        padding: 14px;
    }
    .socialicons .socialicon {
        padding: 2px !important;
    }
    .takemetotext{
        font-size: 8.74px !important;
    }

    .newSocialIcons {
        display: flex;
        gap: 5px;
        margin-top: 25px;
    }

    .arrowone{
        width: 25px;
        padding-left:5px;
    }

    .footersection{
        padding-left: 20px;
        padding-right: 20px;
    }

    .footerformbox {
        background: #FFFFFF;
        padding: 30px;
        border-radius: 15px;
        margin-top: 35px;
    }

    .contactusmail {
        color: #F9F9FB;
        margin-top: 30px;
        font-size: 11px;
        text-align: left;
        font-family: 'Montserrat';
    }

    .footerformbox label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 36px;
        color: #000000;
    }

    .homebtn{
        margin-top: auto;
    }

    .comingsoontext{
        font-weight: 700;
        font-size: 15px !important;
        color: #1A0A5B;
    }
    .takemetobewise{
        padding-left: 11px;
        padding-right: 11px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .takemetobewise img{
        width: 70px;
    }
    .takemetobewise h6{
        font-size: 11px;
        margin-top: auto;
        margin-bottom: auto;
    }
 
    .contactussubtitle {
        color: #F9F9FB;
        font-size: 14px;
        font-family: 'Montserrat';
    }

    .contactustitle {
        color: #F9F9FB;
        font-size: 32px;
        font-family: 'Montserrat';
        font-weight: 800;
    }

    .wcsubmitbtn {
        background-color: #471BF5 !important;
        width: 100%;
        padding: 11px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        border-radius: 9px;
        font-family: 'Montserrat';
    }

 }




 